import React from 'react'
import MenuAdmin from './MenuAdmin/MenuAdmin'
import './MainContent.css'
import IndividualSubmissions from './HomePages/IndividualSubmissions'
import GroupSubmissions from './HomePages/GroupSubmissions'

const MainContent = ({selectedPage}) => {
  let content;

  switch (selectedPage) {
    case 'Individual':
      content = <IndividualSubmissions />

      break;

    case 'Group':
      content = <GroupSubmissions />
      break;

    case 'default':
      content = <IndividualSubmissions />
      break;
  }
  return (
    <>
      <MenuAdmin />
      <div className='p-3'>
        {content}
      </div> 
    </>
  )
}

export default MainContent
