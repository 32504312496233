import React, { useState } from 'react';
import eventsArray from './eventsArray';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ImgContainer = () => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [selectedImgIdx, setSelectedImgIdx] = useState(0); // State to store index of selected image

  const openModal = (imgIdx) => {
    setSelectedImgIdx(imgIdx); // Set the index of selected image
    setImgModalOpen(true);
  };

  const closeModal = () => {
    setImgModalOpen(false);
  };

  return (
    <div className='container'>
      <div className="">
        {eventsArray ? (
          eventsArray.map((event, i) => (
            <div key={i} className='my-5'>
              <h1 className='text-center mb-4'>{event.title}</h1>
              <div className="d-flex flex-wrap flex-row align-items-center gap-3 w-100">
                {event.imgs && event.imgs.map((img, imgIdx) => (
                  <div
                    key={imgIdx}
                    className='w-24 imgContainer'
                    style={{
                      backgroundImage: `url(${img})`,
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                      height: '200px',
                      cursor: 'pointer'
                    }}
                    onClick={() => openModal(imgIdx)}
                  ></div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No events available</p>
        )}
      </div>
  
      <Modal
        isOpen={imgModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="custom-modal"
      >
        {eventsArray && eventsArray[0] && eventsArray[0].imgs[selectedImgIdx] && (
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>&times;</button>
            <img src={eventsArray[0].imgs[selectedImgIdx]} alt="Event" />
          </div>
        )}
      </Modal>
    </div>
  );
  
};

export default ImgContainer;
