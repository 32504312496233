import React, { useState } from 'react';
import IndividualForm from './IndividualForm';
import FamilyMemberForm from './FamilyMemberForm';

const FormContainer = ({ language, options }) => {
  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);

  const handleAddFamilyMember = () => setShowFamilyForm(true);
  const handleBack = () => setShowFamilyForm(false);

  const addFamilyMember = (members) => {
    setFamilyMembers(members);
  };

  const selectedEvents = Object.entries(options)
  .filter(([key, value]) => value)
  .map(([key, value]) => key);

  return (
    <div>
      {showFamilyForm ? (
        <FamilyMemberForm 
          language={language} 
          onBack={handleBack} 
          familyMembers={familyMembers} 
          addFamilyMember={addFamilyMember} 
        />
      ) : (
        <IndividualForm 
          language={language} 
          onAddFamilyMember={handleAddFamilyMember} 
          familyMembers={familyMembers} 
          selectedEvents = {selectedEvents}
        />
      )}
    </div>
  );
};

export default FormContainer;
