import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import slides from '../slides';
import Fraud from '../../img/fraud.mp4';
import Teaser from '../../img/Teaser.mp4';
import Modal from 'react-modal';
import SummaryVideo from '../../videos/SummaryVALOORESLBC.mp4'

Modal.setAppElement('#root');
  
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', right: '5%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', left: '4%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}
  
const Carousel = ({ selectedSlide }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sliderRef = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(selectedSlide);
  }, [selectedSlide]);

  const settings = {
    easing: 'linear',
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const videoId1 = '976wwoTyois';
  const videoId2 = 'EAo9qsc1u4U';

  

  return (
    <>
    <Slider {...settings} ref={sliderRef}>
      {slides.map((slide, index) => {
        let slideToShow = '';

        switch (index){
          case 10:
            slideToShow = (<>
              <div className='carousel_video' style={{backgroundColor: '#000'}}>
              <div className="video_content col-md-6" style={{position: 'relative'}}>
                <video className='video_section1' src={Teaser} muted loop autoPlay/>
              </div>
              <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '2rem' }}>
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
                <div className='d-flex flex-row gap-8 mt-4'>
                  {slide.button === 'yes' && <span style={{top: '23rem', right: '3rem'}} className='btn btn-secondary btn-show1 mt-3' onClick={handleOpenModal}>More Options</span>}
                </div>
              </div>
            </div>
            </>);
            break;
          case 0:
            slideToShow = (<>
            <div className='carousel_video' style={{backgroundColor: '#000'}}>
            <div className="video_content col-md-6" style={{position: 'relative'}}>
              <video className='video_section' src={Fraud} muted loop autoPlay/>
            </div>
            <div className = {`text_content col-md-6 ${slide.position}`} style={{ left: '2rem' }}>
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
              <div className='d-flex flex-row gap-8 mt-4'>
                {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
                {slide.document === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Document</Link></span>}
              </div>
            </div>
          </div>
          </>)
            break;
          default:
            slideToShow = (<>
              <div className="img_content row" style={{ backgroundImage: `url(${slide.image})`, height: '950px' }}>
                {slide.overlay === 'yes' &&  <div className="overlay_bg"></div>}
                <div className = {`text_content col-md-6 ${slide.position}`} style={{left: '2rem' }}>
                  <h1>{slide.title}</h1>
                  <p>{slide.description}</p>
                  <div className='d-flex flex-row gap-8 mt-4'>
                    {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Learn More</Link></span>}
                    {slide.document === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show mt-3'>Document</Link></span>}
                  </div>
                </div>
              </div>
            </>)
            break;
        }
        return (
          <div className='content' style={{ position: 'relative' }} key={index}>
              {slideToShow}
          </div>
        )
      })}
    </Slider>
    <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Add Modal"
      >
        <div className="container">
          <h2 className='text-center mb-5 mt-2'>Choose Your Video</h2>
          <div className="row">
            {/* <div className="col-md-6">
              <h4 class='mb-2'>The Full Episode :</h4>
              <video width="640" height="360" controls controlsList="nodownload">
                  <source src="assets/video/Retail-EN.mp4" type="video/mp4" />
              </video>
              <VideoPlayer videoId={videoId1}/>
            </div> */}
            <div className="col-md-12">
              <h4 class='mb-2'>Best Of :</h4>
              <video width="100%" height="auto" controls controlsList="nodownload">
                  <source src={SummaryVideo} type="video/mp4" />
              </video>
              {/* <VideoPlayer videoId={videoId2}/> */}
            </div>
          </div>
        </div>
       
      </Modal>
    </>

  );
};

export default Carousel;
