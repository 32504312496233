import React from 'react';
import {Link} from 'react-router-dom'
import RetailEn from '../../videos/Retail-EN.mp4'

  
  const Introduction = () => {
   
  return (
    <>
      <div className="container mt-5 intro_container">
        <div className="row align-items-center">
            <div className="col-md-6">
              <div className="video_player-container">
                <video width="100%" height="320" controls controlsList="nodownload">
                    <source src={RetailEn} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content__title">
                <h2>
                  <span>About Us</span>
                </h2>
                <p>
                  Enterprise Business Solutions in Financial Services, Cyber Crimes, BI, Analytics, in'Advanced Certified Anti-Hacking and State Security Clouds Model. VALOORES is one of the leading providers of Enterprise Business Solutions and BI Vertical Decision Making. We are a World Class Company implementing products and services to Tier1 Corp. Our resources pool is spread over North America (US and Canada), Europe (Eastern & Western), Africa, and the Middle East. VALOORES, established in 1989, operates in 17 countries.
                </p>
                <div className="button_div">
                    <Link className="btn btn-show-sec" to='https://demo.valoores.com/valooresdashboards/' target= '_blank'>Request A Demo</Link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Introduction