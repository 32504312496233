import React from 'react';
import { Link } from 'react-router-dom';
import { BsYoutube, BsTwitter} from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {

  const footer1 = [
      {
        title: "ABOUT VALOORES",
        subfooter: [
          {
          subtitle: "Careers",
          linkSub: "https://hr.valoores.com/",
          },
          {
          subtitle: "Press Release",
          linkSub: "/News",
          },
          {
          subtitle: "Quotes",
          linkSub: "http://valoores.com/quotes.php",
          }
        ]
      },
      {
        title: "CONTACT US",
        subfooter: [
        {
        subtitle: "Access Dashboards",
        linkSub: "https://demo.valoores.com/valooresdashboards",
        },
        {
        subtitle: "Office Locations",
        linkSub: "https://valoores.com/contactus.php",
        },
        {
        subtitle: "E-mail",
        linkSub: "https://valoores.com/contactus.php",
        }
      ]
    }
  ];

  const footer2 =[
  {
    title: "LINES OF BUSSINESS",
    subfooter: [
        {
        subtitle: "Banking",
        linkSub: "/banking",
        },
        {
        subtitle: "Analytics",
        linkSub: "https://analytics.valoores.com/",
        },
        {
        subtitle: "Technology",
        linkSub: "https://tech.valoores.com/",
        },
        {
        subtitle: "Academy",
        linkSub: "/hr/academy",
        },
        {
        subtitle: "Insurance",
        linkSub: "https://insurance.valoores.com/",
        },
        {
        subtitle: "Retail",
        linkSub: "https://retail.valoores.com/",
        },
        {
        subtitle: "Healthcare",
        linkSub: "https://healthcare.valoores.com/",
        },
        {
        subtitle: "Multimedia",
        linkSub: "https://multimedia.valoores.com/",
        },
        {
        subtitle: "Government",
        linkSub: "https://gov.valoores.com/",
        },
        {
        subtitle: "Webinars",
        linkSub: "https://webinar.valoores.com/",
        },
  
      ]
    }
  ];

    const footer3=[{
      title: "SERVICES",
      subfooter: [
        {
        subtitle: "AML",
        linkSub: "/banking/riskandcompliance?variable=Aml",
        },
        {
        subtitle: "Fraud Management",
        linkSub: "/banking/riskandcompliance?variable=infraud",
        },
        {
        subtitle: "Regulatory",
        linkSub: "https://banking.valoores.com/regulatoryreportingsolution.php",
        },
        {
        subtitle: "Via",
        linkSub: "https://banking.valoores.com/invia.php",
        },
        {
        subtitle: "Merch",
        linkSub: "https://retail.valoores.com/retail-merchandising-business-suite.php",
        },
        {
        subtitle: "Consultancy",
        linkSub: "https://banking.valoores.com/consultancyservices.php",
        },
        {
        subtitle: "IRFP",
        linkSub: "https://banking.valoores.com/irfp.php",
        },
        {
        subtitle: "Profit",
        linkSub: "/banking/feature?page=inprofit",
        },
        {
        subtitle: "AI/BI",
        linkSub: "https://banking.valoores.com/businessintelligence.php",
        },
        {
        subtitle: "Campaign",
        linkSub: "https://banking.valoores.com/incampaign.php",
        },
        {
        subtitle: "KYC",
        linkSub: "/banking/feature?page=indatagovernance",
        },
        {
        subtitle: "IFRS9",
        linkSub: "/banking/riskandcompliance?variable=IFRS9",
        },
  
      ]
    },
  ];

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row text-white">
            <div className="col-md-4 foot_content">
              <div className=' d-flex flex-direction-row'>
              {footer1.map((items, index) => (
                <div key={index} className='sub_footer p-3'>
                  <h6 className='footer_title'>{items.title}</h6>
                  <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                    {items.subfooter.map((subfooter, subIndex) => (
                      <li key={subIndex}>
                        <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                      </li>                              
                    ))}
                  </ul>
                </div>
              ))}
            </div>
              <div className="footer_search">
                <input type="search" autoComplete="off" placeholder="Search" name="Search" id="search_input"/>
              </div>
            </div>
            <div className="col-md-4 foot_content">
            {footer2.map((items, index) => (
              <div key={index} className='sub_footer p-3'>
                <h6 className='footer_title'>{items.title}</h6>
                <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                  {items.subfooter.map((subfooter, subIndex) => (
                    <li key={subIndex}>
                      <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                    </li>                              
                  ))}
                </ul>
              </div>
            ))}
            </div>
            <div className="col-md-4 foot_content">
            {footer3.map((items, index) => (
              <div key={index} className='sub_footer p-3'>
                <h6 className='footer_title'>{items.title}</h6>
                <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                  {items.subfooter.map((subfooter, subIndex) => (
                    <li key={subIndex}>
                      <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                    </li>                              
                  ))}
                </ul>
              </div>
            ))}
            </div>
          </div>
          <div className="row">
            <div className="footer_socials">
              <div className="footer_socials-icon d-flex align-items-center justify-content-center gap-2">
                  <div className="social_icons d-flex justify-content-center align-items-center"><BsYoutube className="youtube"></BsYoutube></div>
                  <div className="social_icons d-flex justify-content-center align-items-center"><BsTwitter className="twitter"></BsTwitter></div>
                  <div className="social_icons d-flex justify-content-center align-items-center"><FaLinkedinIn className="linkedin"></FaLinkedinIn></div>
              </div>
            </div>
          </div>
        </div>
          <div className="row mt-4 footer_copyright">
            <ul className="copyright_content d-flex flex-row align-items-center gap-3">
                <li>
                    <Link target="_blank" href="" style={{color: 'rgb(255, 255, 255)',cursor:'default'}}>   
                      Copyright © VALOORES 2023. All rights reserved.
                    </Link> 
                </li>
                <li>
                    <Link target="_blank" href="" style={{color: 'rgb(255, 255, 255)'}}>   
                        Privacy
                    </Link> 
                </li>
                <li>
                    <Link target="_blank" href="" style={{color: 'rgb(255, 255, 255)'}}>   
                        Terms &amp; Conditions
                    </Link> 
                </li>
                <li>
                    <Link target="_blank" href="" style={{color: 'rgb(255, 255, 255)'}}>   
                        Contact Us
                    </Link> 
                </li>
                <li>
                    <Link target="_blank" to="./admin" style={{color: 'rgb(255, 255, 255)'}}>   
                        Admin
                    </Link> 
                </li>
            </ul>
          </div>
      </div>
    </>
  )
}

export default Footer