import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FamilyMemberForm from './FamilyMemberForm';
import QRCode from 'qrcode.react';

const IndividualForm = ({ language, selectedEvents }) => {
  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [members, setMembers] = useState([]);
  const qrCodeRef = useRef(null);
  const [qrCodeDataURL, setQRCodeDataURL] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const dataURL = canvas.toDataURL();
        setQRCodeDataURL(dataURL);
      }
    }
  }, [firstName, lastName, email, phone, selectedEvents]);

  const sendEmailWithQR = (qrCodeDataURL) => {
    const emailData = {
      to: email,
      subject: 'Your QR Code for Event Registration',
      content: `
        <p>Hello ${firstName} ${lastName},</p>
        <p>Find attached your QR Code for ${selectedEvents}'s event registration:</p>
        <p>Regards,<br/>Event Team</p>
      `,
      qrCodeDataURL: qrCodeDataURL, // Include QR code Data URL in email data
    };
  
    axios.post('http://127.0.0.1:8000/api/send_email', emailData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  const handleAddFamilyMember = () => {
    if (members.length === 0) {
      setMembers([{ firstName: '', lastName: '', phone: '', email: '' }]);
    }
    setShowFamilyForm(true);
  };

  const handleBack = () => {
    const filteredMembers = members.filter(member => member.firstName && member.lastName);
    setMembers(filteredMembers);
    setShowFamilyForm(false);
  };

  const handleMemberInputChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
  };

  const handleAddMember = () => {
    setMembers([...members, { firstName: '', lastName: '' }]);
  };

  const handleIndividualFormSubmit = (e) => {
    e.preventDefault();
    // const filteredMembers = members.filter(member => member.firstName && member.lastName);
    // if (firstName && lastName && email && phone) {
    //   const individual = {
    //     firstName,
    //     lastName,
    //     email,
    //     phone,
    //   };

    //   const payload = {
    //     ev: selectedEvents,
    //     type: 'Individual',
    //     indiv: individual,
    //     fam: filteredMembers,
    //     lang: language,
    //   };

    //   axios.post('http://127.0.0.1:8000/api/uploadAttend', payload)
    //     .then((response) => {
    //       sendEmailWithQR(qrCodeDataURL);
          
          navigate('/PatriarchDouaihi/qr-code-individual', {
            state: {
              firstName,
              lastName,
              email,
              phone,
              events: selectedEvents.join(', '),
            },
          });
    //     })
    //     .catch((error) => {
    //       console.error('There was an error submitting the form!', error);
    //     });
    // } else {
    //   alert('Please fill in all fields in the Individual Form.');
    // }
  };

  return (
    <div>
      {showFamilyForm ? (
        <FamilyMemberForm
          language={language}
          members={members}
          onBack={handleBack}
          onInputChange={handleMemberInputChange}
          onAddMember={handleAddMember}
        />
      ) : (
        <div className={`indiv_form form mt-5 px-1 bg-white shadow-lg rounded ${language === 'Arabic' ? 'rtl-switch' : ''}`}>
          <form className='p-3' onSubmit={handleIndividualFormSubmit}>
            <h3 className='patriarch-color mb-4 text-center'>{language === 'Arabic' ? 'فرد / عائلة' : 'Individual Form'}</h3>
            <div className="form-group">
              <input
                type="text"
                className="form-control patriarch-color"
                placeholder={language === 'Arabic' ? 'الاسم الأول' : 'First Name'}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control patriarch-color"
                placeholder={language === 'Arabic' ? 'اسم العائلة' : 'Last Name'}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control patriarch-color"
                placeholder={language === 'Arabic' ? 'البريد الإلكتروني' : 'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                className="form-control patriarch-color"
                placeholder={language === 'Arabic' ? 'رقم الهاتف' : 'Phone'}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column gap-3">
              {firstName && lastName && email && phone && (
                <button
                  type="button"
                  onClick={handleAddFamilyMember}
                  className="btn btn-patriarch w-100 mb-0 text-center"
                >
                  {members.length > 0 ? (language === 'Arabic' ? 'عرض أفراد العائلة' : 'Show Family Members') : (language === 'Arabic' ? 'إضافة فرد من العائلة' : 'Add Family Member')}
                </button>
              )}
              <button
                type="submit"
                className="btn btn-patriarch w-100 mt-0"
              >
                {language === 'Arabic' ? 'إرسال' : 'Submit'}
              </button>
            </div>
          </form>
          <div ref={qrCodeRef} style={{ display: 'none' }}>
            <QRCode value={`${firstName} ${lastName} - ${email} - ${phone}`} />
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualForm;
