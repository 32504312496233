import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import 'swiper/swiper.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from 'axios';
import Modal from 'react-modal';

// Install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

const Media = () => {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [fetchedData, setFetchedData] = useState([]);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [to, setTo] = useState('');
  const [Name, setName] = useState('');
  const [Document, setDocument] = useState('');
  const [Profession, setProfession] = useState('');
  const [Company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/documents/show');
        setFetchedData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData(); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 992) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handletoChange = (e) => {
    setTo(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log("Name:", e.target.value);
  };
  
  const handleProfessionChange = (e) => {
    setProfession(e.target.value);
  };
  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const sender = "joenakhle.jn@gmail.com";

  const emailData = new FormData();
  emailData.append('to', to);
  emailData.append('sender', sender);
  
  // Append the selected document's doc_path to emailData
  const DatabaseData = new FormData();
  DatabaseData.append('to', to);
  DatabaseData.append('Name', Name);
  DatabaseData.append('Profession', Profession);
  DatabaseData.append('Company', Company);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Validate email
      const emailValidationRegex = generateEmailValidationRegex();
      const email = emailData.get('to');
  
      if (!emailValidationRegex.test(email)) {
        console.log('Invalid email address.');
        // Handle invalid email address here
        return;
      }
  
      // Append data to FormData objects
      emailData.set('to', `joe.c-nakhle@valoores.com`);
      emailData.set('subject', `Document : ${selectedDocument.doc_name} Request`);
      emailData.set(
        'text',
        `Dear, Kindly note that the following user ${Name} is requesting access to the following document ${selectedDocument.doc_name} bye`
        );
      emailData.set('attachments', `${selectedDocument.doc_path}`);
  
      DatabaseData.set('document_path', selectedDocument.doc_path);
  
      // Send email
      const response = await axios.post('http://localhost:8800/email/send', emailData);
      console.log(response.data); // Response from the server
      setIsPdfModalOpen(false); // Close the modal after sending email
  
      // Save to database
      const databaseResponse = await axios.post('http://localhost:8800/request/requestDatabase', DatabaseData);
      console.log(databaseResponse.data); // Response from database storage
      console.log("Email Data:", {
  to: to,
  subject: `Document : ${selectedDocument.doc_name} Request`,
  // ...
});

console.log("Database Data:", {
  name: Name,
  email: to,
  profession: Profession,
  company: Company,
  document_path: selectedDocument.doc_path,
});

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  

  const generateEmailValidationRegex = () => {
    const excludedDomains = [
      "gmail",
      "yahoo",
      "outlook",
      "hotmail",
      "live",
      "icloud",
      "aol",
      "protonmail",
      "yandex",
      "mail",
      "zoho",
      "gmx",
      "tutanota",
      "fastmail",
      "inbox"
    ];
    const excludedDomainsPattern = excludedDomains.map(domain => `(${domain.replace(/\./g, '\\.')})`).join('|');
    const regexPattern = new RegExp(`^(?!.*@(${excludedDomainsPattern}))([\\w.-]+)@([\\w.-]+)\\.([a-zA-Z]{2,})$`);
    return regexPattern;
  };

  const handlePdfModalOpen = (doc) => {
    setSelectedDocument(doc);
    setIsPdfModalOpen(true);
  };

  const handlePdfModalClose = () => {
    setSelectedDocument(null);
    setIsPdfModalOpen(false);
  };

  return (
    <div className="media__container" id="media"> 
      <div className="container">
        <div className="row">
          <div className="media__title pt-5 pb-3">
            <h2>
              <span>Valoores </span>Multimedia
            </h2>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        Pagination={3}
        navigation={true}
        className="mySwiper"
      >
        {fetchedData.map((doc) => (
          <SwiperSlide key={doc.id}>
            <div className="card">
              <div className="card-header">
                <img
                  src={require(`../../img/${doc.image_path.split('/').pop()}`)}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="card-body">
                <h5>{doc.doc_name}</h5>
                <Link onClick={() => handlePdfModalOpen(doc)}>Read More...</Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Modal
        isOpen={isPdfModalOpen}
        onRequestClose={handlePdfModalClose}
        contentLabel="Pdf Modal"
      >
        {selectedDocument && (
          <div className='container p-3 text-center' style={{width: '95%'}}>
            <h5 style={{color: "#007bc1"}}>Request {selectedDocument.doc_name}</h5>
            <p >Please fill in the below to access {selectedDocument.doc_name} </p>
            <form onSubmit={handleSubmit} className='my-4 d-flex flex-column align-items-center'>
              <input type="text" onChange={handleNameChange} className='form-control' name="fullname" placeholder='Your Full Name' id="" />
              <input type="email" onChange={handletoChange} className='form-control' name="email" placeholder='Your Business E-mail' id="" />
              <input type="text" onChange={handleProfessionChange} className='form-control' name="profession" placeholder='Your Profession' id="" />
              <input type="text" onChange={handleCompanyChange} className='form-control' name="company" placeholder='Your Company' id="" />
              <div className="captcha">
                <div className="spinner">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span className="checkmark"><span>&nbsp;</span></span>
                  </label>
                </div>
                <div className='w-100 d-flex flex-row justify-content-center align-items-center'>
                  <h5 className='w-100 d-flex flex-row justify-content-center align-items-center border-0 m-0'>I AM NOT A ROBOT</h5>
                </div>
              </div>
              <button
                type="submit"
                className='btn btn-primary'
                disabled={!isChecked}>
                Submit
              </button>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Media;
