import React from 'react'
import Logo from '../../img/logo.png'
import {Link} from 'react-router-dom'
import {BsChevronRight} from 'react-icons/bs'
import {AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin} from 'react-icons/ai'
import Share from '../../icons/share.png'
import Links from '../../icons/links.png'



const SideMenu = () => {

  const solution = [
    {
      title: "in'Marketing Optimization",
      link : "/banking/feature?page=marketing-optimization"
    },
    {
      title: "in'Asset Management",
      link : "/banking/feature?page=inassetmanagement"
    },
    { 
      title: "in'Data Governance",
      link : "/banking/feature?page=indatagovernance"
    },
    {
      title: "in'Payment Services",
      link : "/banking/feature?page=financialservices"
    },
    {
      title: "in'Risk Compliance",
      link : "/banking/feature?page=risk-and-compliance"
    },
    {
      title: "in'Cards Merchant",
      link : "'/banking/feature?page=cardsmerchant"
    },
    {
      title: "in'Analytics",
      link : "/analytics"
    },
    {
      title: "in'Crypto",
      link : "/banking/feature?page=crypto"
    },
    {
      title: "in'Kernel",
      link : "/banking/feature?page=inkernel"
    },
    {
      title: "in'Profit",
      link : "/banking/feature?page=inprofit"
    },
  ]

  const social = [
    {
      platform: <AiFillFacebook/>,
      class: "facebook_icon"
    },
    {
      platform: <AiFillTwitterSquare />,
      class: "twitter_icon"
    },
    {
      platform: <AiFillLinkedin />,
      class: "linkedin_icon"
    },
  ]

  const quickLinks = [
    { 
      title: "Become a Partner",
      link : "https://banking.valoores.com/clientspartners.php"
    },
    {
      title: "Contact us",
      link : "/aboutUs?variable=Contact"
    },
    {
      title: "Spotlight News",
      link : "https://valoores.com/spotlightnews.php"
    },
    {
      title: "Job Application",
      link : "https://hr.valoores.com/"
    },
  ]
  return (
    <>
      <div className="banking_sideMenu">
        {/* <div className="row banking_SideMenu-items">
          <h6>Request Offline Application</h6>
        </div> */}
        <div className="row banking_SideMenu-items">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Logo} alt="logo" style={{width: '24px'}}/>
             VALOORES Solutions
          </h6>
          <ul style={{listStyle: 'none'}}>
            {solution.map((item, index) =>
              <li key={index}>
                <Link target='_blank' style={{textDecoration: 'none', }} to={item.link}>
                  <BsChevronRight />{item.title}
                </Link>
            </li>
            )}
          </ul>
        </div>
        <div className="row banking_SideMenu-items">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Share} alt="logo" style={{width: '24px'}}/>
             VALOORES Solutions
          </h6>
          <div className='d-flex flex-row'>
            {social.map((item, index) =>
              <span key={index} className='social_span'>
                <Link target='_blank' className='d-flex' style={{textDecoration: 'none', fontSize: '35px'}} to=  {item.link}>
                  <span className={item.class}>
                    {item.platform}
                  </span>
                </Link>
              </span>
            )}
          </div>
        </div>
        <div className="row banking_SideMenu-items mt-5">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Links} alt="logo" style={{width: '24px'}}/>
            Quick Links
          </h6>
          <ul style={{listStyle: 'none'}}>
            {quickLinks.map((item, index) =>
              <li key={index}>
                <Link target='_blank' style={{textDecoration: 'none', }} to={item.link}>
                  <BsChevronRight />{item.title}
                </Link>
            </li>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SideMenu