import React from 'react'

const Banner = ({img, title, desc}) => {
  return (
    <div className='crypto_banner d-flex flex-column align-items-center justify-content-center' style={{backgroundImage: `url(${img})`}}>
        <div className="overlay"></div>
        <div className="text-content text-white position-relative" style={{zIndex: 3}}>
            <h1>{title}</h1>
            <p>{desc}</p>
        </div>
    </div>
  )
}

export default Banner
