import React from 'react'
import Banner from './Banner'
import './Gallery.css'
import GalleryBanner from '../../img/banners/recruitment.jpg'
import ImgContainer from './ImgContainer'

const Gallery = () => {
  return (
    <>
        <Banner img={GalleryBanner} title="Gallery" desc="" />
        <ImgContainer />
    </>
  )
}

export default Gallery
