import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Patriarch from '../../img/patriarch1.png';
import logoDouaihi from '../../img/logoDouaihi.png';

const AddGroup = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({ groupData: {}, groupMembers: [], language: 'English' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizationDetails, setOrganizationDetails] = useState({
    name: '',
    members: [],
  });
  const [memberForm, setMemberForm] = useState({
    fullName: '',
    email: '',
    phone: '',
  });
  const [selectedEventsG, setSelectedEventsG] = useState([]);

  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/api/getEventData/${id}`)
      .then(response => {
        setEventData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching event data');
        setLoading(false);
      });
  }, [id]);

  const handleAddMember = () => {
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      members: [...prevDetails.members, { fullName: '', phone: '', email: '' }],
    }));
  };

  const handleGroupFormSubmit = (e) => {
    e.preventDefault();
  
    // Filter out empty rows from organizationDetails.members
    const filteredMembers = organizationDetails.members.filter(member => (
      member.fullName.trim() !== '' &&
      member.email.trim() !== '' &&
      member.phone.trim() !== ''
    ));
  
    // Update state with filtered members
    setOrganizationDetails(prevDetails => ({
      ...prevDetails,
      members: filteredMembers,
    }));
  
    // Submit the form data to the API
    axios.put(`http://127.0.0.1:8000/api/updateEventData/${id}`, {
      groupMembers: filteredMembers
    })
    .then(response => {
      alert('Your group has been updated successfully.');
      // Optionally, you can fetch updated event data here if needed
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while updating the group. Please try again.');
    });
  };
  
  

  const sendEmail = (message, recipientEmail) => {
    const emailData = {
      to: recipientEmail,
      subject: 'Group Update Notification',
      content: message,
    };

    axios.post('http://127.0.0.1:8000/api/send_email', emailData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { groupMembers, language } = eventData;

  return (
    <div className="patriarch-douaihi">
      <div
        className="banner-patriarch d-flex align-items-start justify-content-center"
        style={{ backgroundImage: `url(${Patriarch})` }}
      >
        <div
          className="position-absolute bg-white rounded-circle"
          style={{ right: "5%", top: "2%", zIndex: "2" }}
        >
          <img
            src={logoDouaihi}
            alt=""
            style={{ zIndex: "10", width: "7rem", height: "7rem" }}
          />
        </div>
        <div className="overlay"></div>
        <div
          className="position-relative p-5 d-flex flex-column align-items-center"
          style={{
            top: "10%",
            zIndex: "10",
            fontSize: "4rem",
            borderRadius: "25px",
            background: "rgba(0,0,0,0.5)",
          }}
        >
          <h1 className="text-white mb-5">Patriarch Douaihy Event</h1>
          <form onSubmit={handleGroupFormSubmit}>
            <div
              className="event-data px-3 text-center"
              style={{ direction: `${language === "Arabic" ? "rtl" : "ltr"}` }}
            >
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{fontSize: '1rem'}}>{language === "Arabic" ? "الاسم الكامل" : "Full Name"}</th>
                      <th style={{fontSize: '1rem'}}>{language === "Arabic" ? "البريد الإلكتروني" : "Email"}</th>
                      <th style={{fontSize: '1rem', direction: 'ltr'}}>{language === "Arabic" ? "رقم الهاتف" : "Phone"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupMembers.map((member, index) => (
                      <tr key={index}>
                        <td>
                          <input className='form-control' disabled type="text" value={member.fullName}/>
                        </td>
                        <td>
                          <input className='form-control' disabled type="text" value={member.email} />
                        </td>
                        <td className="text-end" style={{ direction: "ltr" }}>
                          <input className='form-control' disabled type="text" value={member.phone} />
                        </td>
                      </tr>
                    ))}
                    {/* Render additional rows with enabled inputs for new members */}
                    {organizationDetails.members.map((member, index) => (
                      <tr key={index + groupMembers.length}>
                        <td>
                          <input
                            className='form-control'
                            type="text"
                            value={member.fullName}
                            onChange={(e) => {
                              const newMembers = [...organizationDetails.members];
                              newMembers[index].fullName = e.target.value;
                              setOrganizationDetails((prevDetails) => ({
                                ...prevDetails,
                                members: newMembers,
                              }));
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className='form-control'
                            type="text"
                            value={member.email}
                            onChange={(e) => {
                              const newMembers = [...organizationDetails.members];
                              newMembers[index].email = e.target.value;
                              setOrganizationDetails((prevDetails) => ({
                                ...prevDetails,
                                members: newMembers,
                              }));
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className='form-control'
                            type="text"
                            value={member.phone}
                            onChange={(e) => {
                              const newMembers = [...organizationDetails.members];
                              newMembers[index].phone = e.target.value;
                              setOrganizationDetails((prevDetails) => ({
                                ...prevDetails,
                                members: newMembers,
                              }));
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    {/* Disable the "Add Another Member" button if the last added row is empty */}
                  </tbody>
                </table>
              </div>
              <div className='d-flex flex-column gap-1 mb-4'>
                <button
                    type="button"
                    className="btn btn-primary w-100 mt-3"
                    onClick={handleAddMember}
                    disabled={
                    !organizationDetails.members[organizationDetails.members.length - 1].fullName ||
                    !organizationDetails.members[organizationDetails.members.length - 1].email ||
                    !organizationDetails.members[organizationDetails.members.length - 1].phone
                    }
                >
                    {language === 'Arabic' ? 'إضافة فرد آخر' : 'Add Another Member'}
                </button>
                <button type="submit" className="btn btn-success w-100 mt-3">
                  {language === 'Arabic' ? 'ارسال' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddGroup;
