import React from 'react'
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import {RiSingleQuotesL, RiSingleQuotesR} from 'react-icons/ri'
import {GrTechnology, GrOptimize, GrGlobe} from 'react-icons/gr'
import {HiOutlineUsers} from 'react-icons/hi'
import {AiFillFilePdf} from 'react-icons/ai'
import Retail1 from '../../../img/retail/retail1.jpg'
import Retail2 from '../../../img/retail/retail2.jpg'
import Retail3 from '../../../img/retail/retail3.jpg'
import Retail4 from '../../../img/retail/retail4.jpg'
import Retail5 from '../../../img/retail/retail5.jpg'
import Retail6 from '../../../img/retail/retail6.jpg'
import Retail7 from '../../../img/retail/retail7.jpg'
import Retail8 from '../../../img/retail/retail8.jpg'
import Retail9 from '../../../img/retail/retail9.jpg'
import Retail10 from '../../../img/retail/retail10.jpg'
import Retail11 from '../../../img/retail/retail11.jpg'
import Retail12 from '../../../img/retail/retail12.jpg'
import Retail13 from '../../../img/retail/retail13.jpg'
import Retail14 from '../../../img/retail/retail14.jpg'
import Retail15 from '../../../img/retail/retail15.jpg'
import Retail16 from '../../../img/retail/retail16.jpg'
import Retail17 from '../../../img/retail/retail17.jpg'
import Retail18 from '../../../img/retail/retail18.jpg'
import Retail19 from '../../../img/retail/retail19.jpg'
import retailDoc3 from '../../../docs/retail/Cvs.pdf'
import img1 from '../../../img/retail/analytics.jpg'
import img2 from '../../../img/retail/omnichannel.jpg'




const Retail = () => { 
  let retail = [
    {
    title: "Empowering Retailers with Actionable Insights",
    desc: "Empowering retailers with precise geospatial data solutions for success in a competitive market. Flourish with our tailored, high-quality solutions in today's data-driven business environment. Gain the edge to thrive and surpass competitors."
  },
  // {
  //   title: "Personalized Real-World Data Collection Services",
  //   desc: "Valoores Crowd Intelligence System: Tailored real-world data collection services for diverse retail segments. Personalized solutions aligned with clients' goals and objectives. Actionable data for relevant insights."
  // },
  {
    title: "Retailer Success with Valoores Crowd Intelligence",
    desc: "At Valoores Crowd Intelligence System, we empower retailers with comprehensive geospatial data, tailored solutions, compliance, quality assurance, and a competitive edge. We are committed to your success, providing ongoing support in today's dynamic business environment."
  },
  ]
  
  
  const Intro = () => {
    return (
      <>
        <h1 className='my-5'><RiSingleQuotesL />Geospatial: Bridging physical and digital worlds, unlocking insights, empowering decisions.<RiSingleQuotesR /></h1>
        <div className="d-flex intro_content gap-3">
          {retail.map((ret, index) => (
            <div key={index}>
              <Fade bottom>
                <div className="retail__box text-center p-3">
                  <h5>{ret.title}</h5>
                  <p>{ret.desc}</p>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </>
    )
  }
  
  const footfall = [
    {
      title: 'Valuable Insights through Geospatial Data',
      desc: 'Analyzing geospatial data provides valuable insights about footfall and visitation patterns for retailers. It helps determine optimal store locations, identify high-traffic areas, and understand customer behavior within stores.'
    },
    {
      title: 'Targeted Marketing Strategies',
      desc: 'Dwell time analyses offer insights into customer duration in stores, enabling targeted marketing strategies. These strategies encourage increased dwell time, boost sales, and create personalized experiences for customer preferences.'
    },
    {
      title: 'Enhancing In-Store Customer Experience',
      desc: 'Optimizing customer experience through geospatial data insights. Identify congestion points, optimize product placement, and increase dwell time for more effective promotions and displays.'
    },
    {
      title: 'Maximizing Customer Flow and Sales Performance',
      desc: 'Optimize store locations with footfall data to maximize customer flow, brand visibility, and sales. Uncover expansion opportunities through competitor footfall analysis.'
    },
  ]
  
  const Footfall = () =>{
    return (
      <>
      {/* <div className="section_seperator"></div> */}
        <div className="col-md-6">
            <div className="row ">
              <div className="col-md-6 img_section">
                <div className="row  px-1 pb-2">
                  <Fade top>
                    <div style={{backgroundImage: `url(${Retail1})`, height: '45rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
                <div className="row px-1 pb-2">
                  <Fade bottom>
                      <div style={{backgroundImage: `url(${Retail2})`, height: '20rem', backgroundSize : 'cover'}}></div>
                    </Fade>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row px-1 pb-2">
                  <Fade left>
                    <div style={{backgroundImage: `url(${Retail3})`, height: '20rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
                <div className="row px-1 pb-2 mb-5">
                  <Fade left>
                    <div style={{backgroundImage: `url(${Retail4})`, height: '45rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
              </div>
            </div>
        </div>
        <div className="col-md-6">
          <div className="row"></div>
            <h1><RiSingleQuotesL />Geospatial data: Guiding retailers to success with insights from footfall patterns and visitation analyses. Unlock the edge with optimized store locations, enhanced experiences, and targeted marketing strategies.<RiSingleQuotesR /></h1>
          <div className="footfall_section gap-3 mt-5">
          {footfall.map((foot, index) => (
            <div className="footfall_section-sub text-center" key={index}>
              <h5>{foot.title}</h5>
              <p>{foot.desc}</p>
            </div>
          ))}
          </div>
        </div>
      </>
    )
  }
  
  const dataRole = [
    {
      title: 'The Value of Geospatial Data',
      desc: 'Geospatial data, encompassing raw geospatial data and points of interest data, holds immense value for businesses seeking actionable insights. This section emphasizes the importance of analyzing geospatial data to gain detailed information about consumer behavior, market trends, and the competitive landscape. It highlights how businesses can make informed decisions and optimize operations effectively by leveraging this data.',
      img: Retail5,
    },
    {
      title: 'Strategic Store Expansion and Marketing',
      desc: 'Geospatial data plays a crucial role in strategic store expansion and developing effective marketing strategies. This section focuses on how businesses can analyze geospatial data and consumer demographics to strategically open new stores in high-demand areas with a potential customer base. It also highlights how analyzing consumer behavior patterns and location-based insights enables businesses to tailor marketing campaigns, align messaging, and promotional efforts according to specific regions or target audiences.',
      img: Retail6,
    },
    {
      title: 'Optimizing Supply Chain Management',
      desc: 'Geospatial data is instrumental in optimizing supply chain management. This section highlights how businesses can utilize this data to optimize networks, transportation routes, delivery times, and costs. By adopting a data-driven approach, companies can streamline logistics operations, improve inventory management, and enhance overall efficiency in their supply chains.',
      img: Retail7,
    },
    {
      title: 'Insights for Retailers and Enhanced Customer Experiences',
      desc: 'For retailers, geospatial data provides valuable insights into foot traffic patterns, in-store movement, and customer preferences. This section emphasizes the significance of analyzing geospatial data to identify peak shopping hours, popular product categories, and customer behavior. It showcases how retailers can leverage this data to refine store layouts, strategically place products, and create personalized shopping experiences, ultimately leading to enhanced customer experiences and overall success.',
      img: Retail8,
    },
  ]
  
  const Geospatial = () =>{
    return(
      <>
        <div className="dataRole_section d-flex flex-column">
        <h1 className='text-center mb-5'>Geospatial data role in retail world</h1>
          {dataRole.map((data, index) => (
            <div key={index} className={`dataRole_section-part d-flex justify-content-between flex-col ${index % 2 === 0 ? 'flex-row-r ' : ' '}` }>
              {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade>}
            <div className="seperator"></div>
            <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
              <h4>{data.title}</h4>
              <h6>{data.desc}</h6>
            </div>
          </div>
          ))}
        </div>
      </>
    )
  }
  
  const locationBased = [
    {
      image: Retail9,
      title: "Targeted Marketing Campaigns",
      desc: "Geospatial data enables businesses like supermarkets, pharmacies, and restaurants to create highly targeted marketing campaigns. By segmenting target audiences based on geographic location, proximity to stores, and shopping preferences, businesses can achieve impressive engagement and conversion rates.",
    },
    {
      image: Retail10,
      title: " Geofencing and Promotions",
      desc: "Retail marketers can leverage geospatial data to develop geofencing campaigns that trigger promotions and offers when potential customers are near a store. This strategy drives foot traffic and increases the likelihood of in-store visits, enhancing customer engagement and sales.",
    },
    {
      image: Retail11,
      title: "Effective Out-of-Home Advertising",
      desc: "Geospatial data enhances out-of-home advertising by strategically placing ads in high-traffic areas based on foot traffic patterns. This maximizes visibility and effectiveness while providing accurate measurement of ad views and in-store visits, optimizing spending and maximizing ROI.",
    },
  ]
  
  const LocAdv = () => {
    return (
      <>
      {/* <div className="section_seperator"></div> */}
        <h1 className='text-center mb-5'>Location-based Advertising and Marketing</h1>
        <div className="d-flex loc-based flex-row gap-3 flex-wrap">
          {locationBased.map((loc, index) => (
            <div key={index} class='w-33'>
              <div className="loc_section card" style={{ height: '30rem'}}>
                <div className="card-header">
                  <Fade top>
                    <div className="loc_section-img" style={{backgroundImage : `url(${loc.image})`}}></div>
                  </Fade>
                </div>
                <Fade top>
                  <div className="card-body text-center">
                    <h4 className='mt-3 mb-4'>{loc.title}</h4>
                    <h6>{loc.desc}</h6>
                  </div>
                </Fade>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
  
  
  const ecommerce = [
    {
      icon: <GrTechnology />,
      title: "Convenience in Online Retail",
      desc: "Technology-driven shopping experience.",
    },
    {
      icon: <HiOutlineUsers />,
      title: "Shifting Customer Preferences",
      desc: "Digital platforms over physical stores.",
    },
    {
      icon: <GrGlobe />,
      title: "Key Factors for Success",
      desc: "Competitive pricing, swift delivery, excellent services.",
    },
    {
      icon: <GrOptimize />,
      title: "Optimizing Delivery Operations",
      desc: "Streamlining logistics with accurate geospatial data.",
    },
  ]
  
  const Ecommerce = () => {
    return (
      <>
        {/* <div className="section_seperator"></div> */}
          <h1 className='text-center mb-5'>Ecommerce logistics and operations</h1>
          <div className="">
            <div>
            {/* <div key={index}> */}
              <div className="row ecommerce_row flex-wrap" >
                {ecommerce.map((ecom, index) => (
                  <div className="w-23 ecommerce_section d-flex flex-column justify-content-center align-items-center text-center gap-3">
                    <Zoom top>
                    <h1>{ecom.icon}</h1>
                    </Zoom>
                    <Fade bottom>
                    <h5>{ecom.title}</h5>
                    <p>{ecom.desc}</p>
                    </Fade>
                  </div>
                ))}
              </div>
            </div>
        </div>
      </>
    )
  }
  
  const supplyChain = [
    {
      title: 'Optimizing Supply Chain',
      desc: 'Location and Point of Interest (POI) data play a crucial role in helping retailers optimize their supply chain operations, streamline distribution networks, and improve transportation efficiency.',
      img: Retail12,
    },
    {
      title: 'Strategic Distribution Network',
      desc: 'By analyzing geospatial data, retailers can assess their existing distribution networks, identify inefficiencies, and make informed decisions to optimize the movement of goods from suppliers to stores.',
      img: Retail13,
    },
    {
      title: 'Effective Inventory Management',
      desc: 'Understanding regional demand patterns, customer preferences, and store performance allows retailers to allocate inventory more effectively. This ensures products are readily available, enhancing customer satisfaction and minimizing stockouts.',
      img: Retail14,
    },
    {
      title: 'Informed Decision-Making',
      desc: 'Utilizing location and POI data empowers retailers to make informed decisions that improve their supply chain operations, streamline distribution networks, enhance transportation efficiency, and optimize inventory management. This leads to increased operational efficiency and higher customer satisfaction.',
      img: Retail15,
    },
  ]
  
  const Chain = () =>{
    return(
      <>
        <div className="dataRole_section d-flex flex-column">
        <h1 className='text-center mb-5'>Supply-chain optimization</h1>
          {supplyChain.map((supply, index) => (
            <div key={index} className={`dataRole_section-part d-flex justify-content-between flex-col ${index % 2 === 0 ? 'flex-row-r' : ''}`}>
              {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${supply.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${supply.img})`, flex : '2'}}></div>}</Fade>}
            <div className="seperator"></div>
            <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
              <h4>{supply.title}</h4>
              <h6>{supply.desc}</h6>
            </div>
          </div>
          ))}
        </div>
      </>
    )
  }
  
  
  const customerSeg = [
    {
      title: 'Valuable Insights through Geospatial Data',
      desc: 'Analyzing geospatial data provides valuable insights about footfall and visitation patterns for retailers. It helps determine optimal store locations, identify high-traffic areas, and understand customer behavior within stores.'
    },
    {
      title: 'Targeted Marketing Strategies',
      desc: 'Dwell time analyses offer insights into customer duration in stores, enabling targeted marketing strategies. These strategies encourage increased dwell time, boost sales, and create personalized experiences for customer preferences.'
    },
    {
      title: 'Enhancing In-Store Customer Experience',
      desc: 'Optimizing customer experience through geospatial data insights. Identify congestion points, optimize product placement, and increase dwell time for more effective promotions and displays.'
    },
    {
      title: 'Maximizing Customer Flow and Sales Performance',
      desc: 'Optimize store locations with footfall data to maximize customer flow, brand visibility, and sales. Uncover expansion opportunities through competitor footfall analysis.'
    },
  ]
  
  const CustomerSeg = () =>{
    return (
      <>
      {/* <div className="section_seperator"></div> */}
      <div className="col-md-6">
          <div className="row"></div>
            <h1><RiSingleQuotesL />Geospatial data: Guiding retailers to success with insights from footfall patterns and visitation analyses. Unlock the edge with optimized store locations, enhanced experiences, and targeted marketing strategies.<RiSingleQuotesR /></h1>
          <div className="footfall_section gap-3 mt-5">
          {customerSeg.map((seg, index) => (
            <div className="footfall_section-sub text-center" key={index}>
              <h5>{seg.title}</h5>
              <p>{seg.desc}</p>
            </div>
          ))}
          </div>
        </div>
        <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="row img_section px-1 pb-2">
                  <Fade top>
                    <div style={{backgroundImage: `url(${Retail16})`, height: '45rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
                <div className="row img_section px-1 pb-2">
                  <Fade bottom>
                      <div style={{backgroundImage: `url(${Retail17})`, height: '20rem', backgroundSize : 'cover'}}></div>
                    </Fade>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row img_section px-1 pb-2">
                  <Fade left>
                    <div style={{backgroundImage: `url(${Retail18})`, height: '20rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
                <div className="row img_section px-1 pb-2">
                  <Fade left>
                    <div style={{backgroundImage: `url(${Retail19})`, height: '45rem', backgroundSize : 'cover'}}></div>
                  </Fade>
                </div>
              </div>
            </div>
        </div>
        
      </>
    )
  }

  const docs = 
  [
    {
      title: 'Incorporating Location Intelligence within Retail Organizations: Case Studies Approach',
      link: retailDoc3,
      img: '',
      pdf: 'yes',
    },
    {
      title: 'VCIS - Geospatial Analytics; Driving Omnichannel Retail Success with Location Intelligence',
      link: '/Geospatial/retail-analytics',
      img: img2,
      pdf: 'no',
    },
    {
      title: 'VCIS - A New Era in Data and Analytics.',
      link: '/Geospatial/retail-data',
      img: img1,
      pdf: 'no',
    },
  ]

  return (
    <>
    {/* Introduction */}
      <div className="retail__content-all container mt-5">
        <Intro />
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Footfall />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Geospatial />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <LocAdv />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Ecommerce />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Chain />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <CustomerSeg />
        </div>
        {/* <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row my-5">
          <h2>More Materials:</h2>
          {docs.map((item, index) => (
            <div className="col-md-3 mt-4" key={index}>
              <Link to={item.link} target='_blank'>
                <div className="row d-flex align-items-start flex-column justify-content-center gap-3">
                  {`${item.pdf}` === 'yes' ? 
                    <div className="col-md-4">
                      <AiFillFilePdf className='w-100 h-100' style={{color: "#cf0000"}}/>
                    </div>
                    : 
                      <img src={item.img} alt='retail'/>
                  }
                  <div className="col-md-10">
                    <h5>{item.title}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div> */}
      </div>
    </>
  )
}

export default Retail