import React from 'react';
import FamilyMemberTable from './FamilyMemberTable';

const FamilyMemberForm = ({ language, members, onBack, onInputChange, onAddMember }) => {
  return (
    <div className={`form mt-5 px-1 bg-white shadow-lg rounded w-100 ${language === 'Arabic' ? 'rtl-switch' : ''}`}>
      <div className='px-3'>
        <div>
          <button onClick={onBack} className="btn btn-patriarch mb-3">{language === 'Arabic' ? 'رجوع' : 'Back'}</button>
        </div>
        <FamilyMemberTable
          language={language}
          members={members}
          onInputChange={onInputChange}
          onAddMember={onAddMember}
        />
          <button style={{marginTop: '-3rem'}} onClick={onBack} className="btn btn-patriarch mb-3 w-100">{language === 'Arabic' ? 'تأكيد' : 'Confirm'}</button>
      </div>
    </div>
  );
};

export default FamilyMemberForm;
