import React from 'react';
import YouTube from 'react-youtube';
import ReactHtmlParser from 'react-html-parser'; // Import the library
import Axios from './database/axios.jsx';
import RetailEn from '../../videos/Retail-EN.mp4'

const Introduction = () => {
  const fetchedData = Axios({ path: '/section' });
  const videoId = 'lRoRZGMc4cs';

  return (
    <>
      <div className="container mt-5 intro_container">
        {fetchedData.map((intro) => (
          <>
            {intro.type === 'intro' && (
              
              <div className="row align-items-center" key={intro.id}>
                <div className={intro.image_container}>
                  <div className={intro.link}>
                  <video width="100%" height="320" controls controlsList="nodownload">
                    <source src={RetailEn} type="video/mp4" />
                  </video>
                  </div>
                </div>
                <div className={intro.text_container}>
                  <div className={intro.class}>
                    {/* Use ReactHtmlParser to parse and render the HTML content */}
                    {ReactHtmlParser(intro.title)}
                    {ReactHtmlParser(intro.description)}
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default Introduction;
