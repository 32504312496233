import React, { useState } from 'react';
import Patriarch from '../../img/patriarch1.png';
import LanguageSelection from './LanguageSelection';
import EventSelection from './EventSelection';
import TypeSelection from './TypeSelection';
import FormContainer from './FormContainer';
import GroupForm from './GroupForm';
import logoDouaihi from '../../img/logoDouaihi.png'
import './PatriarchDouaihi.css';

const PatriarchDouaihi = () => {
  const [currentScreen, setCurrentScreen] = useState('languageSelection');
  const [language, setLanguage] = useState(null);
  const [options, setOptions] = useState({ Bkerke: false, Ehden: false });

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setCurrentScreen('eventSelection');
  };

  const handleOptionToggle = (option) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const handleNextClick = () => {
    setCurrentScreen('typeSelection');
  };

  const handleTypeSelect = (type) => {
    if (type === 'individual') {
      setCurrentScreen('FormContainer');
    } else if (type === 'group') {
      setCurrentScreen('groupForm');
    }
  };

  return (
    <div className="patriarch-douaihi">
      <div
        className="banner-patriarch d-flex align-items-start justify-content-center"
        style={{ backgroundImage: `url(${Patriarch})` }}
      >
        <div className='position-absolute bg-white rounded-circle' style={{right: '5%', top: '2%', zIndex: '2'}}>
          <img src={logoDouaihi} alt="" style={{zIndex: '10', width: '7rem',height: '7rem'}}/>
        </div>
        <div className="overlay"></div>
        <div className="position-relative p-5 d-flex flex-column align-items-center event_container">
          <h1 className="text-white">
            Patriarch Douaihy Event
          </h1>
          {currentScreen === 'languageSelection' && (
            <LanguageSelection handleLanguageSelect={handleLanguageSelect} />
          )}
          {currentScreen === 'eventSelection' && (
            <EventSelection
              language={language}
              handleOptionToggle={handleOptionToggle}
              handleNextClick={handleNextClick}
              options={options}
            />
          )}
          {currentScreen === 'typeSelection' && (
            <TypeSelection language={language} handleTypeSelect={handleTypeSelect} />
          )}
          {currentScreen === 'FormContainer' && <FormContainer language={language} options={options} />}
          {currentScreen === 'groupForm' && <GroupForm language={language} options={options} />}
        </div>
      </div>
    </div>
  );
};

export default PatriarchDouaihi;
