import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Pages/Home';
import HomeTrial from './Pages/HomeTrial';
import Ceo from './Pages/Ceo';
import Mission from './Components/Mission';
import AboutUs from './Components/aboutUs';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Pdf from './Components/PdfViewer';
import Business from './Pages/Business';
import Geospatial from './Components/index/Geospatial';
import RetailData from './Components/index/Geospatial/RetailData';
import RetailAnalytics from './Components/index/Geospatial/RetailAnalytics';
import Admin from './admin/adminPage';
import Login from './admin/LoginPage';
import Register from './admin/RegisterPage';
import ContactUs from './Components/index/ContactUs';
import Banking from './Pages/Banking/Banking';
import BTrial from './Pages/Banking_Trial/Banking';
import Analytics from './Pages/Analytics/Analytics';
import Feature from './Pages/Banking/Feature';
import RiskComplianceSub from './Pages/RiskCompliance/Feature';
import Healthcare from './Pages/Healthcare/Healthcare';
import Career from './Pages/Career/Career';
import CVS from './Pages/Cvs/CVS';
import NotFound from './Pages/notFound';
import Academy from './Pages/Academy/Academy';
import Logo from './Pages/TheLogo/TheLogo';
import Gov from './Pages/Government/Gov';
import Bi from './Pages/Bi/Bi';
import AI from './Components/Ai';
import DareToOvercome from './Components/DareToOvercome';
import Clients from './Components/ClientsPage/ClientPage';
import Multimedia from './Components/Multimedia';
import MindMap from './Components/MindMap/MindMap';
import CaseManagements from './Components/Geospatial/CaseManagements.jsx'
import News from './Pages/News/News'
// import CaseManagements from './components/Geospatial/CaseManagements.jsx';
import CaseGeospatial from './Components/Geospatial/CaseGeospatial.jsx';
// import CEO from './Components/AboutUs/CEO/CEO.jsx';
import CaseAnalytics from './Components/Geospatial/CaseAnalytics.jsx';
import CaseBiometrics from './Components/Geospatial/CaseBiometrics.jsx';
import CaseRiskAndThreatManagement from './Components/Geospatial/CaseRiskAndThreatManagement.jsx';
import CaseKYC from './Components/Geospatial/CaseKYC.jsx';
// import Employment from './php/Employment.php'
import './App.css';
// import './responsive.scss';
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AnalyticsDisplay from './Pages/Analytics/AnalyticsPages/AnalyticsDisplay';
import InProfit from './Pages/inProfit/Feature';
import Marketing from './Pages/inMarketing/Feature';
import InAnalytics from './Pages/inAnalytics/Feature';
import InKernel from './Pages/inKernel/Feature';
import InAsset from './Pages/inAsset/Feature';
import GeospatialI from './Components/Geospatial/GeospatialI';
import Digital from './Components/Digital/Digital.jsx';
import Insurance from './Components/Insurance/insurance.jsx'
import Overview from './Components/Insurance/Overview.jsx';
import PatriarchDouaihi from './Pages/PatriarchDouaihi/PatriarchDouaihi.jsx';
import QRCodeDisplayIndividual from './Pages/PatriarchDouaihi/QRCodeDisplayIndividual.jsx';
import QRCodeDisplayGroup from './Pages/PatriarchDouaihi/QRCodeDisplayGroup.jsx';
import Gallery from './Pages/Gallery/Gallery.jsx';
import Crypto from './Pages/Services/Crypto/Crypto.jsx';
import AddGroup from './Pages/PatriarchDouaihi/AddGroup.jsx';
import InDataA from './Pages/inData/Feature';
import HomeDashboard from './Pages/PatriarchDouaihi/Dashboard/pages/HomeDashboard.jsx';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldRenderNavbar = !location.pathname.includes('admin');
  const shouldRenderFooter = !location.pathname.includes('PdfViewer');
  const isLoggedIn = true;
  const [showNavbar, isShowNavbar] = useState(false);
  const TITLE = 'Valoores';
  useEffect(() => {
    if (!shouldRenderNavbar && !isLoggedIn) {
      navigate('/admin/Login');
    }
  }, [shouldRenderNavbar, isLoggedIn, navigate]);
  console.log(shouldRenderNavbar);

  return (
    <div>
      <>
        {!shouldRenderNavbar && (
          <Routes>
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/admin/Login" element={<Login />} />
            <Route path="/admin/Register" element={<Register />} />
            <Route path="/" element={<Home />} />
            <Route path="/trial" element={<HomeTrial />} />
            <Route path="/business" element={<Business />} />
            <Route path="/ceo" element={<Ceo />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/aboutUs/*" element={<AboutUs />} />
            <Route path="/Geospatial" element={<Geospatial />} />
            <Route path="/Geospatial/retail-data" element={<RetailData />} />
            <Route path="/Geospatial/retail-analytics" element={<RetailAnalytics />} />
            <Route path="/Geospatial/cvs" element={<CVS />} />
            {/* <Route path="/aboutUs/leaders" element={<Leaders />} /> */}
            <Route path="/admin" element={<Admin />} />
            <Route path="/pdfViewer" element={<Pdf />}></Route>
            <Route path="/ai" element={<AI />}></Route>
            {/* <Route path="/banking" element={<Banking />}></Route> */}
            <Route path="/banking" element={<Banking />}></Route>
            <Route path="/btrial" element={<BTrial />}></Route>
            <Route path="/analytics" element={<Analytics />}></Route>
            <Route path="/analytics/*" element={<AnalyticsDisplay />}></Route>
            <Route path="/banking/feature" element={<Feature />}></Route>
            <Route path="banking/riskandcompliance/*" element={<RiskComplianceSub />}></Route>
            <Route path="banking/inProfit/*" element={<InProfit />}></Route>
            <Route path="banking/inDataA/*" element={<InDataA />}></Route>
            <Route path="banking/inMarketing/*" element={<Marketing />}></Route>
            <Route path="banking/inAnalytics/*" element={<InAnalytics />}></Route>
            <Route path="banking/inKernel/*" element={<InKernel />}></Route>
            <Route path="banking/inAsset/*" element={<InAsset />}></Route>
            <Route path="/hr/career" element={<Career />}></Route>
            <Route path="/hr/academy" element={<Academy />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/aboutUs/logo" element={<Logo />}></Route>
            <Route path="/solutions/gov" element={<Gov />}></Route>
            <Route path="/solutions/healthcare" element={<Healthcare />}></Route>
            <Route path="/DareToOvercome" element={<DareToOvercome />}></Route>
            <Route path="/MindMap" element={<MindMap />}></Route>
            <Route path="/Multimedia" element={<Multimedia />}></Route>
            <Route path="/*" element={<NotFound />}></Route>
            <Route path="/Bi" element={<Bi />}></Route>
            <Route path="/Geospatial-Analysis/Case-Managements" element={<CaseManagements/>}></Route>
            <Route path="/Geospatial-Analysis/Case-Geospatial" element={<CaseGeospatial/>}></Route>
            <Route path="/Geospatial-Analysis/Case-Analytics" element={<CaseAnalytics />}></Route>
            <Route path="/Geospatial-Analysis/Case-Biometrics" element={<CaseBiometrics />}></Route>
            <Route path="/Geospatial-Analysis/Case-RiskAndThreatManagement" element={<CaseRiskAndThreatManagement />}></Route>
            <Route path="/Geospatial-Analysis/Case-KYC" element={<CaseKYC />}></Route>
            <Route path="/Geospatial-Analysis" element={<Geospatial />}></Route>
            <Route path="/GeospatialI" element={<GeospatialI />}></Route>
            <Route path="/Digital" element={<Digital />}></Route>
            <Route path="/Insurance" element={<Insurance />}></Route>
            <Route path="/Insurance/overview/*" element={<Overview />}></Route>
            {/* <Route path="/Employment" element={<Employment />}></Route> */}
          </Routes>
        )}

        {!shouldRenderFooter && (
          <Routes>
            <Route path="/pdfViewer" element={<Pdf />} />
          </Routes>
        )}

        {location.pathname.includes('PatriarchDouaihi') ? (
          <Routes>
            <Route path="/PatriarchDouaihi" element={<PatriarchDouaihi />} />
            <Route path="/PatriarchDouaihi/qr-code-individual" element={<QRCodeDisplayIndividual />} />
            <Route path="/PatriarchDouaihi/qr-code-group" element={<QRCodeDisplayGroup />} />
            <Route path="/PatriarchDouaihi/add/:id" element={<AddGroup />} />
            <Route path="/PatriarchDouaihi/dashboard" element={<HomeDashboard />} />
          </Routes>
        ) : (
          <>
            {shouldRenderNavbar && <Navbar />}
            <Helmet>
              <title>{TITLE}</title>
            </Helmet>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/trial" element={<HomeTrial />} />
              <Route path="/business" element={<Business />} />
              <Route path="/ceo" element={<Ceo />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/aboutUs/*" element={<AboutUs />} />
              <Route path="/Geospatial" element={<Geospatial />} />
              <Route path="/Geospatial/retail-data" element={<RetailData />} />
              <Route path="/Geospatial/retail-analytics" element={<RetailAnalytics />} />
              <Route path="/Geospatial/cvs" element={<CVS />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/pdfViewer" element={<Pdf />} />
              <Route path="/ai" element={<AI />} />
              <Route path="/banking" element={<Banking />} />
              <Route path="/btrial" element={<BTrial />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/analytics/*" element={<AnalyticsDisplay />} />
              <Route path="/banking/feature" element={<Feature />} />
              <Route path="banking/riskandcompliance/*" element={<RiskComplianceSub />} />
              <Route path="banking/inProfit/*" element={<InProfit />} />
              <Route path="/hr/career" element={<Career />} />
              <Route path="/hr/academy" element={<Academy />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/aboutUs/logo" element={<Logo />} />
              <Route path="/solutions/gov" element={<Gov />} />
              {/* <Route path="/solutions/healthcare" element={<Healthcare />} /> */}
              <Route path="/DareToOvercome" element={<DareToOvercome />} />
              <Route path="/MindMap" element={<MindMap />} />
              <Route path="/Multimedia" element={<Multimedia />} />
              <Route path="/*" element={<NotFound />} />
              <Route path="/Bi" element={<Bi />} />
              <Route path="/Geospatial-Analysis/Case-Managements" element={<CaseManagements />} />
              <Route path="/Geospatial-Analysis/Case-Geospatial" element={<CaseGeospatial />} />
              <Route path="/Geospatial-Analysis/Case-Analytics" element={<CaseAnalytics />} />
              <Route path="/Geospatial-Analysis/Case-Biometrics" element={<CaseBiometrics />} />
              <Route path="/Geospatial-Analysis/Case-RiskAndThreatManagement" element={<CaseRiskAndThreatManagement />} />
              <Route path="/Geospatial-Analysis/Case-KYC" element={<CaseKYC />} />
              <Route path="/Geospatial-Analysis" element={<Geospatial />} />
              <Route path="/GeospatialI" element={<GeospatialI />} />
              <Route path="/Digital" element={<Digital />} />
              <Route path="/Insurance" element={<Insurance />} />
              <Route path="/News" element={<News />} />
              <Route path="/Gallery" element={<Gallery />} />
              <Route path="/Services/Crypto" element={<Crypto />} />
            </Routes>
            <ContactUs />
            {shouldRenderFooter && <Footer />}
          </>
        )}
      </>
    </div>
  );
}

export default App;