import React from 'react'

const Banner = (props) => {
  return (
    <div className='banner_img-ai text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${props.img})`}}>
        <div className="banner_overlay-ai"></div>
        <div className='w-100 flex-column' style={{top: '10%'}}>
        <div className="container banner_content-ai flex-row">
            <div className="left_side" style={{flex : '3', paddingRight: '6rem'}}>
                <p style={{lineHeight : '2.5rem'}}>{props.desc}</p>
            </div>
            <div className="right_side" style={{flex : '1.5'}}>
            </div>
        </div>
        <div className="container mt-4 d-flex gap-3" style={{position : 'relative'}}>
        </div>
        </div>
    </div>
  )
}

export default Banner