import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import Patriarch from '../../img/patriarch1.png';
import { Modal, Button } from 'react-bootstrap';



const QRCodeDisplayGroup = () => {
  const location = useLocation();
  const formData = location.state;
  const [readDocs, isReadDocs] = useState(false);
  const [showModal, setShowModal] = useState(true);


  if (!formData) {
    return <div>No data found.</div>;
  }

  const formattedData = `
  First Name: ${formData.firstName}
  Email: ${formData.email}
  Phone: ${formData.phone}
  Events: ${formData.events}
`;

const handleCloseModal = () => {
  setShowModal(false);
  isReadDocs(true);
};


const handleAccept = () => {
  isReadDocs(true);
  setShowModal(false);

}
  return (
    <>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>General Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: "50dvw", overflowY: "scroll"}}>
          <ul>
            <li>Please make sure you register all the group via our application. And come together as one group to the event. (if you do not want to arrive with the group, register as an individual).</li>
            <li>After registration you will receive a QR code. That remains with the group leader.</li>
            <li>The designated group leader will be responsible for maintaining communication regarding the number of individuals in their group and their method of transportation to the venue.</li>
            <li>Leaders should have the group QR code ready in order to access the event.</li>
            <li>GROUP LEADERS: The leaders will collect Bus cards from Nahr El Kalb, Zekrit Road, First Bldg. to the Left, next to the Old Roman Bridge. Starting July 25 till July 31 between 9:00 am and 6:00 pm.</li>
            <li>Only buses with bus cards will have access to the roads leading to Bkerki! Make sure that bus cards are placed visibly on the front and sides of the bus. Also that all participants are on that bus. (individual cars must sign up as an individual).</li>
            <li>Please ensure that you park your bus according to the section assigned to you.</li>
            <li>To have access directly to Bkerke. Your group will be assigned a card including: name of the group, color, and logo.</li>
            <li>Your group will have a color card to remember the bus. Pass out the color card to each individual. Make sure they keep the color card with them at all times. Kindly ensure that you and the group board the correct bus upon departure.</li>
            <li>After parking, make your way to the event.</li>
            <li>Doors will open at 6:00 pm (a special program will be prepared from 6-8 pm prior to the beatification event).</li>
            <li>For your convenience and safety, a brief search will be conducted.</li>
            <li>Please leave all food in your vehicles! Food is prohibited.</li>
            <li>Be in appropriate dress code as we respect this holy day.</li>
            <li>Each group will have assigned seating. Please follow the instructions provided by the hostess to locate your designated seating area. Moving the seats is not allowed!</li>
            <li>In consideration of others attending the event, we kindly request that all participants silence their phones and remain seated throughout the duration of the mass.</li>
            <li>If assistance is needed, do not hesitate to approach event staff or volunteers stationed throughout the venue.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

      {readDocs && 
        <div
          className="banner-patriarch d-flex align-items-start justify-content-center align-items-center"
          style={{ backgroundImage: `url(${Patriarch})` }}
        >
          <div className="overlay"></div>
          <div className="qr-code-display d-flex flex-column align-items-center justify-content-center gap-3 p-5 bg-white rounded">
            <h3 className='patriarch-color'>Scan this QR code to get more information</h3>
            <QRCode value={formattedData} renderAs="svg" />
            <div className="form-data">
              <p><strong>Organization Name:</strong>  {formData.name}</p>
              <p><strong>Event Name:</strong> {formData.events}</p>
              {/* <h5>Events Detail:</h5> */}
              <ul>
                {/* <li></li> */}
                {/* <li></li> */}
                {/* <li></li> */}
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default QRCodeDisplayGroup;
