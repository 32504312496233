import Img1 from '../../img/EventsImgs/img1.jpg'
import Img2 from '../../img/EventsImgs/img2.jpg'
import Img3 from '../../img/EventsImgs/img3.jpg'
import Img4 from '../../img/EventsImgs/img4.jpg'
import Img5 from '../../img/EventsImgs/img5.jpg'
import Img6 from '../../img/EventsImgs/img6.jpg'
import Img7 from '../../img/EventsImgs/img7.jpg'
import Img8 from '../../img/EventsImgs/img8.jpg'
import Img9 from '../../img/EventsImgs/img9.jpg'
import Img10 from '../../img/EventsImgs/img10.jpg'
import Img11 from '../../img/EventsImgs/img11.jpg'
import Img12 from '../../img/EventsImgs/img12.jpg'
import Img13 from '../../img/EventsImgs/img13.jpg'
import Img14 from '../../img/EventsImgs/img14.jpg'
import Img15 from '../../img/EventsImgs/img15.jpg'
import Img16 from '../../img/EventsImgs/img16.jpg'
import Img17 from '../../img/EventsImgs/img17.jpg'
import Img18 from '../../img/EventsImgs/img18.jpg'
import Img19 from '../../img/EventsImgs/img19.jpg'
import Img20 from '../../img/EventsImgs/img20.jpg'
import Img21 from '../../img/EventsImgs/img21.jpg'
import Img22 from '../../img/EventsImgs/img22.jpg'
import Img23 from '../../img/EventsImgs/img23.jpg'
import Img24 from '../../img/EventsImgs/img24.jpg'
import Img25 from '../../img/EventsImgs/img25.jpg'
import Img26 from '../../img/EventsImgs/img26.jpg'
import Img27 from '../../img/EventsImgs/img27.jpg'
import Img28 from '../../img/EventsImgs/img28.jpg'
import Img29 from '../../img/EventsImgs/img29.jpg'

const eventsArray = [
  {
    title: 'Beirut Times',
    imgs: [
      Img11, Img3, Img7, Img1, Img25, Img20, Img15, Img4, Img29, Img2,
      Img18, Img10, Img14, Img28, Img6, Img9, Img27, Img13, Img5, Img21,
      Img22, Img8, Img16, Img12, Img26, Img24, Img23, Img19, Img17
    ]
  }
]

export default eventsArray;
