import React from 'react';

const LanguageSelection = ({ handleLanguageSelect }) => (
  <div className='choices mt-5'>
    <h2 className='text-white mb-5'>Select your language / اختر اللغة المناسبة</h2>
    <div className="lang">
      <div className="lang_container" onClick={() => handleLanguageSelect('English')}><h3 className='patriarch-color'>English</h3></div>
      <div className="lang_container" onClick={() => handleLanguageSelect('Arabic')}><h3 className='patriarch-color'>العربية</h3></div>
    </div>
  </div>
);

export default LanguageSelection;
