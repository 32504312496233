import React from 'react';
import { Link } from 'react-router-dom';

const EventSelection = ({ language, handleOptionToggle, handleNextClick, options }) => (
  <div className='choices mt-5'>
    <h2 className='text-white mb-5 text-center'>{language === 'Arabic' ? 'اختر الفعاليات التي تود حضورها' : 'Select the event/s that you would like to attend'}</h2>
    <div className="options">
      <div
        className={`option_container ${options.Bkerke ? 'selected' : ''}`}
        onClick={() => handleOptionToggle('Bkerke')}
      >
        <h3 className='patriarch-color'>{language === 'Arabic' ? 'بكركي' : 'Bkerke'}</h3>
      </div>
      <div
        className={`option_container ${options.Ehden ? 'selected' : ''}`}
        onClick={() => handleOptionToggle('Ehden')}
      >
        <h3 className='patriarch-color'>{language === 'Arabic' ? 'إهدن' : 'Ehden'}</h3>
      </div>
    </div>
    <div className="next mt-5">
      {(options.Bkerke || options.Ehden) && (
        <Link className="btn btn-patriarch" onClick={handleNextClick}>
          Next &rarr;
        </Link>
      )}
    </div>
  </div>
);

export default EventSelection;