import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const Group = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get('http://127.0.0.1:8000/api/getAll') // Replace with your API endpoint for group data
      .then(response => {
        const filteredData = response.data.filter(item => item.group_members && item.group_members.length > 0);
        setData(filteredData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleFamilyClick = (family) => {
    setSelectedFamily(family);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFamily(null);
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <div>
      <h1>Group Submissions</h1>
      <table className="table table-hover">
        <thead>
          <tr>
            <th style={{ fontSize: '1rem' }}>Full Name</th>
            <th style={{ fontSize: '1rem' }}>Group Name</th>
            <th style={{ fontSize: '1rem' }}>Email</th>
            <th style={{ fontSize: '1rem' }}>Phone</th>
            <th style={{ fontSize: '1rem' }}>Event</th>
            <th style={{ fontSize: '1rem' }}>Group Members</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>{item.group_data ? item.group_data.fullName : 'N/A'}</td>
              <td>{item.group_data ? item.group_data.organization : 'N/A'}</td>
              <td>{item.group_data ? item.group_data.email : 'N/A'}</td>
              <td>{item.group_data ? item.group_data.phone : 'N/A'}</td>
              <td>{item.events && item.events.length > 0 ? item.events.join(', ') : 'N/A'}</td>
              <td>
                <Link className='btn btn-patriarch' onClick={() => handleFamilyClick(item.group_members)}>
                  View Group Members
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Group Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFamily !== null && selectedFamily.length > 0 ? (
            <div style={{height: '31rem', overflowY: 'scroll'}}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFamily.map((member, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">{`${member.fullName}`}</td>
                      <td className="text-capitalize">{`${member.phone}`}</td>
                      <td className="text-capitalize">{`${member.email}`}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No group members registered.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Group;
