import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import bankingSlides from './bankingSlides';

const SectionCarousel = ({ setActiveIndex }) => {
  const [activeIndex, setActiveIndexState] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
    setActiveIndex(activeIndex);
  }, [activeIndex, setActiveIndex]);


  const settings = {
    // autoplay: true,
    // autoplaySpeed: 3000,
    focus: false,
    easing: 'linear',
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndexState(next);
    },
  };

  const makeActive = (index) => {
    setActiveIndexState(index);
  };

  return (
    <>
      <div className='geo_container mt-5' style={{ position: 'relative' }}>
        <Slider {...settings} ref={sliderRef}>
          {bankingSlides.map((banking, index) => (
            <div className='geo_content' style={{ position: 'relative' }} key={index}>
              <div className="img_geo-content row" style={{ backgroundImage: `url(${banking.image})` }}>
                <div className="overlay_bg"></div>
                <div className="text_geo-content col-md-4" style={{ top: '20%', left: '2rem' }}>
                  <h1 className='w-75'>{banking.title}<br /></h1>
                  <p>{banking.description}</p>
                  <Link target="_blank" to={banking.link} className='w-25 btn btn-primary btn-show'>View Solutions</Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='geo_thumb d-flex justify-content-center text-white gap-1 align-items-center'>
          <div className="container flex-row d-flex w-75 align-items-center justify-content-center">
            {bankingSlides.map((banking, index) => {
              const icon = banking.icon; 
              // const iconWhite = banking.icon_white;
              return (
                <div
                  className={index === activeIndex ? 'geo_thumb-box active' : 'geo_thumb-box'}
                  onClick={() => makeActive(index)}
                  key={index}
                >
                  <div className="overlay_bg"></div>
                  <img alt="icon"
                    className={index === activeIndex ? '' : 'white_scale'}
                    style={{ width: '36px', height: '36px' }}
                    src={icon} 
                  />
                  {banking.header}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionCarousel;
