import React from 'react'
import SideMenu from './SideMenu'
import Banner from './Banner'
import NewsBanner from '../../img/banners/NewsBanner.jpg'
import NewsSection from './NewsSection'
import './News.css'

const News = () => {
  return (
    <>
        <Banner img = {NewsBanner}/>
        <div className="container mt-3">
            <h4 className='text-danger' style={{borderBottom: '1px dashed'}}>News / Press Release</h4>
            <div className="d-flex flex-row">
                <div style={{flex: '4'}}>
                    <NewsSection />
                </div>
                <div style={{flex: '1'}}>
                    <SideMenu />
                </div>
            </div>
        </div>
    </>
  )
}

export default News
