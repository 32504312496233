import React from 'react'
import Banner from './Banner'
import CryptoBanner from '../../../img/banners/crypto_banner.jpg'
import './Crypto.css'

const Crypto = () => {
  return (
    <>
    <Banner img={CryptoBanner} title="Crypto" />
    </>
  )
}

export default Crypto
