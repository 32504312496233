import React from 'react';

const TypeSelection = ({ language, handleTypeSelect }) => (
  <div className='choices mt-5'>
    <div className="selection text-center">
      <div className="selection_container" onClick={() => handleTypeSelect('individual')}>
        <h3 className='patriarch-color'>{language === 'Arabic' ? 'فرد / عائلة' : 'Individual / Family'}</h3>
      </div>
      <div className="selection_container" onClick={() => handleTypeSelect('group')}>
        <h3 className='patriarch-color'>{language === 'Arabic' ? 'مجموعة' : 'Group'}</h3>
      </div>
    </div>
  </div>
);

export default TypeSelection;
