const JobsArray = 
[
    {
        category:'Information Technology',
        title:'Web Developer',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/web_developer.pdf',
        order: '',
        level: 'Junior - Mid',
    },
    {
        category:'Engineering And Design',
        title:'Senior Software Developer',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/senior_software_developer.pdf',
        order: '',
        level: 'Senior',
    },
    {
        category:'Engineering And Design',
        title:'DevOps Engineer',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '',
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Software Engineer',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/software_engineer.pdf',
        order: '',
        level: 'Junior / Intermediate',
    },
    // {
    //     category:'Engineering And Design',
    //     title:'Data Analytics Lead',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    // {
    //     category:'Engineering And Design',
    //     title:'Data Scientist',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '/docs/hr/data_scientist.pdf',
    //     order: '',
    //     level: 'Junior / Intermediate',
    // },
    // {
    //     category:'Engineering And Design',
    //     title:'Quality Assurance Engineer',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    // {
    //     category:'Engineering And Design',  
    //     title:'Machine Learning Engineer',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Junior / Intermediate',
    // },
    // {
    //     category:'Business Development',
    //     title:'Business Development Regional - North America',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    // {
    //     category:'Business Development',
    //     title:'Business Development Regional - Europe',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    // {
    //     category:'Business Development',
    //     title:'Business Development Regional - GCC',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    {
        category:'Product Management',
        title:'Project Manager',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/project_manager.pdf',
        order: '',
        level: 'Senior',
    },
    {
        category:'Product Management',
        title:'Business Analyst',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '',
        order: '',
        level: 'Junior',
    },
    // {
    //     category:'Information Technology',
    //     title:'Web Designer',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Senior',
    // },
    // {
    //     category:'Information Technology',
    //     title:'Database Administrator(DBA)',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Intermediate',
    // },
    // {
    //     category:'Information Technology',
    //     title:'Cyber Security Specialist',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Intermediate',
    // },
    // {
    //     category:'Information Technology',
    //     title:'Oracle Developer',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Intermediate',
    // },
    // {
    //     category:'Information Technology',
    //     title:'Weblogic / Websphere / Tomcat Administrator',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Intermediate',
    // },

    {
        category:'Information Technology',
        title:'Database Integrator',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/dba_integrator.pdf',
        order: '',
        level: 'Intermediate',
    },
    // {
    //     category:'Information Technology',
    //     title:'IT',
    //     desc: '',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.me/92441014348451',
    //     jobDesc: '',
    //     order: '',
    //     level: 'Junior / Intermediate',
    // },
    {
        category:'Professional Services And Consulting',
        title:'Account Manager',
        desc: '',
        location: 'Bauchrieh',
        link: 'https://form.jotform.me/92441014348451',
        jobDesc: '/docs/hr/account_manager.pdf',
        order: '',
        level: 'Junior / Intermediate',
    },
]

export default JobsArray