import React from 'react'
import Banner from './Banner'
import Solutions from '../admin/pages/solutions'
import Paralax from '../Pages/Banking/Paralax'
import Services from './index/Services'
import MultimediaBanner1 from '../img/multimedia/banner1.jpg'
import Testimonials from '../Components/Multimedia/Testimonials'
import Recruitment from '../Components/Multimedia/Recruitment'

const Multimedia = () => {
  return (

     <>
        {/* <Navbar /> */}
        <Banner 
            img={MultimediaBanner1}
            title='VCIS Architecture'
            description= {
                <div className='w-75 texxt-center'>
                    Valoores app provides fast, secure, and offline analytics to turn data into actionable insights.
                </div>
            }
        />
        <Solutions />
        <Paralax img={MultimediaBanner1} />
        <Services />
        <Testimonials />
        <Recruitment />
        {/* <Footer /> */}
    </>
  )
}

export default Multimedia