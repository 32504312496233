import News01 from '../../img/News/News0.jpg'
import News1 from '../../img/News/News1.jpg'
import News2 from '../../img/News/News2.jpg'
import News3 from '../../img/News/News3.jpg'
import News4 from '../../img/News/News4.jpg'
import News5 from '../../img/News/News5.jpg'
import News6 from '../../img/News/News6.jpg'
import News7 from '../../img/News/News7.jpg'
import News8 from '../../img/News/News8.jpg'
import News9 from '../../img/News/News9.jpg'
import News10 from '../../img/News/News10.jpg'
import News11 from '../../img/News/News11.jpg'
import News12 from '../../img/News/News12.jpg'
import News13 from '../../img/News/News13.jpg'
import News14 from '../../img/News/News14.jpg'
import News15 from '../../img/News/News15.jpg'
import News16 from '../../img/News/News16.jpg'
import News17 from '../../img/News/News17.jpg' 
import News18 from '../../img/News/News18.jpg'
import News19 from '../../img/News/News19.jpg'
import News20 from '../../img/News/News20.jpg'
import News21 from '../../img/News/News21.jpg'
import News22 from '../../img/News/News22.jpg'
import News23 from '../../img/News/News23.jpg'
import News24 from '../../img/News/News24.jpg'
import News25 from '../../img/News/News25.jpg'

const NewsArray = [
    {
        img: News01,
        title: 'بيروت تايمز تُطلق مبادرة من أجل مساعدة الشباب اللبنان',
        date:'June 6, 2024',
        desc: 'بيروت تايمز تُطلق مبادرة من أجل مساعدة الشباب اللبنان',
        link: 'https://www.transparency.news/news/327895?c4'
    },
    {
        img: News1,
        title: 'VALOORES Financial Performance Management System Life Cycle.',
        date:'March 31, 2021',
        desc: 'Adopting VALOORES inFinancial Performance Management System services secure the grip over the Regulatory & Financial diligence across the banks worldwide branches and operations.',
        link: ''
    },
    {
        img: News2,
        title: 'VALOORES Delivers to the Association of Banks in Lebanon The Digital Acceleration Platform to serve as a Hub for the Banking sector… after Corona.',
        date:'September 08, 2020',
        desc: 'VALOORES is pleased to have anchored its relationship with Association of Banks in Lebanon over a widespread roadmap that spans a suite of asset classes, and covers all key functions of Master Data Governance, Compliance, Business Intelligence, Digital Academy, and Analytics frameworks.',
        link: ''
    },
    {
        img: News3,
        title: 'VALOORES announces the Next Generation v20 ecosystem.',
        date:'May 20, 2020',
        desc: 'VALOORES announces the Next Generation v20 ecosystem that marches at the same drum with the latest Digital Transformation guidelines, to align with the metamorphosing Business models, benchmark Data Readiness, and explore Data Challenges when addressing Complex and Changing Business requirements.',
        link: ''
    },
    {
        img: News4,
        title: 'VALOORES signs with Arabia Insurance Company a Regulatory Compliance suite of value-based Financial Crime asset classes.',
        date:'February 7, 2020',
        desc: 'VALOORES signs with Arabia Insurance Group A Regulatory Compliance Suite of asset classes the Implementation of a Global - International 21st Century Financial Integrity Technology Platform in Financial Crime Anti Money Laundering',
        link: ''
    },
    {
        img: News5,
        title: 'VALOORES QATAR as a Main Compliance Speaker under the auspices of Qatar Central Bank',
        date:'January 27, 2020',
        desc: 'VALOORES QATAR as a Main Compliance Speaker under the auspices of Qatar Central Bank, World Union of Arab Bankers, Compliance Challenges & Combating Financial Crime Conference, 29th and 30th of January, 2020, Sharq Village & Spa - a Ritz-Carltron Hotel, Doha, Qatar.',
        link: ''
    },
    {
        img: News6,
        title: 'VALOORES signs The Regulatory Compliance Reporting with CSCBank',
        date:'May 10th, 2019',
        desc: "VALOORES signs The Regulatory Compliance Reporting with CSCBank with a Road Map of the fully-fledged VALOORES solutions. CSCBank, a specialized bank, adopts VALOORES' in'Regulatory Compliance Reporting services to industrialize its regulatory reporting diligence and secure its Compliance landscape.",
        link: ''
    },
    {
        img: News7,
        title: 'VALOORES & The Association of Banks in Lebanon',
        date:'May 6, 2019',
        desc: 'VALOORES Academy in collaboration with The Association of Banks in Lebanon is organizing a workshop entitled "Introduction to Capital Markets" that will be held on May 6, 2019, from 9:00 a.m till 5:00 p.m. at the Radisson Blu Martinez hotel, Ain El Mreisseh.',
        link: ''
    },
    {
        img: News8,
        title: 'A Recruitment Seminar at University of Balamand',
        date:'April 11, 2019',
        desc: 'The seminar was provided to a selection of promising future business candidates. The students were introduced to the following topics. It was an opportunity for them to gain insights on the means and tools to optimize their skills and expertise in order to excel in their future careers.',
        link: ''
    },
    {
        img: News9,
        title: 'Balamand Career Fair 2019',
        date:'April 09, 2019',
        desc: 'The students met with key professionals from the HR & Communications Department, the Data Science & Analytics Department, the Business Analysis Department and were presented with the following topics.',
        link: ''
    },
    {
        img: News10,
        title: 'A Job Shadowing Program Day for AUB Students',
        date:'March 08, 2019',
        desc: 'The students met with key professionals from the HR & Communications Department, the Data Science & Analytics Department, the Business Analysis Department and were presented with the following topics.',
        link: ''
    },
    {
        img: News11,
        title: 'VALOORES Case Study Workshop at CNAM',
        date:'March 01, 2019',
        desc: 'After the successful Corporate Presentation at Le Cnam Liban on February 26, VALOORES conducted a Case-Study Workshop on Analytics on March 01, 2019. After the presentation on the Rise of Data Economy and VALOORES Analytics Projects, the students were provided with Hands on Case Studies.',
        link: ''
    },
    {
        img: News12,
        title: 'VALOORES held a Seminar at Le Cnam Liban',
        date:'February 26, 2019',
        desc: 'The presentation was provided to a selection of promising future candidates majoring in Engineering, Mathematics, Management, Statistics, Business, Data Science, Finance, Computer Science.',
        link: ''
    },
    {
        img: News13,
        title: 'Workshop on "Economic and Financial Indicators"',
        date:'January, 2019',
        desc: 'The Association of Banks in Lebanon organized a Workshop Day in collaboration with VALOORES ACADEMY for the Mid-Level Banks Executives on "ECONOMIC AND FINANCIAL INDICATORS” that was held on January 29, 2019 at the Radisson Blu Martinez Hotel, Ain El Mreisseh.',
        link: ''
    },
    {
        img: News14,
        title: 'VALOORES held a Corporate Presentation at AUB',
        date:'October 2018',
        desc: 'VALOORES held a Corporate Presentation at AUB with the Collaboration of the Career Management Services at the School of Business on October 09, 2018.',
        link: ''
    },
    {
        img: News15,
        title: 'VALOORES @ AUB - Workshop on Analytics',
        date:'October, 2018',
        desc: 'The successful day for VALOORES at AUB on the 9th of October was followed by a Case-study workshop on Analytics on October 26, 2018 with the collaboration and support of the Career Management Services Office at AUB.',
        link: ''
    },
    {
        img: News16,
        title: 'VALOORES & THE LEBANESE SCHOOL - QATAR',
        date:'December, 2018',
        desc: "VALOORES' Multimedia Department successfully re-designed the website of The Lebanese School - Qatar. The new interface helped boost The Lebanese School's online presence while providing a user-friendly and easy access platform that communicates the school's activities with the parents and students.",
        link: ''
    },
    {
        img: News17,
        title: 'VALOORES & VERACODE',
        date:'November, 2018',
        desc: 'We are very pleased to announce that VALOORES has received Veracode attestation report which ensures that we have appropriate internal controls in place for security against unauthorized access from inception through production into the software lifecycle, availability for operation and use as committed or agreed, and confidentially without sacrificing speed or innovation.',
        link: ''
    },
    {
        img: News18,
        title: 'Lebanon & Gulf Bank',
        date:'July, 2018',
        desc: 'July 13th, 2018 - VALOORES signs The Financial Crime Anti Money Laundering & Regulatory Compliance Reporting with Lebanon & Gulf Bank. … with a Road Map of the fully-fledged VALOORES solutions.',
        link: ''
    },
    {
        img: News19,
        title: 'Bank of Beirut',
        date:'Jan, 2018',
        desc: "VALOORES signs in'Financial Crime - Anti Money Laundering & Fraud Monitoring Solution, with Bank Of Beirut Group. Another Milestone after VALOORES' iconic success story with its subsidiary BoB Finance, the Western Union Agent",
        link: ''
    },
    {
        img: News20,
        title: 'BDL',
        date:'Dec, 2017',
        desc: "The Central Bank of Lebanon, an iconic, progressive, and reputable Central Bank, a worldwide reference in its proven successful financial model and strategies, adopts VALOORES' framework to secure and industrialize its Regulatory Authority Governance, Risk, and Compliance. The implementation spans a suite of asset classes, and covers a Next Gen Analytics-enabled life cycle.",
        link: ''
    },
    {
        img: News21,
        title: 'Al Mawarid',
        date:'Oct, 2017',
        desc: "Al-Mawarid Bank, a leading commercial bank, adopts VALOORES in'Financial Crime services to contain Anti Money Laundering and meet Regulatory diligence. The implementation spans a suite of asset classes, and covers all conventional key functions of AML.",
        link: ''
    },
    {
        img: News22,
        title: 'Fransabank',
        date:'June, 2017',
        desc: "VALOORES' platform delivers complete control over every step of the Regulatory Reporting and Analysis Processes, which are key considerations for FRANSABANK Group. FRANSABANK Group, the third-largest Lebanese bank, expands its use of VALOORES' in'Risk Compliance and Capital Calculations platform, to meet its Regulatory Reporting obligations. The implementation spans a suite of asset classes and covers all key functions of the Analytical and Regulatory Reporting life cycle; from LCR to CCAR, alongside the breadth of the reporting requirements.",
        link: ''
    },
    {
        img: News23,
        title: "VALOORES, Leading Solution Provider, releases in'Via, a world class BI solution, to the Banking & Finance industry.",
        date:'November 07, 2014',
        desc: "VALOORES accentuated its predictive Analytics platform to sustain the Banking & Finance industry's decision-making, anticipate the market's future needs, and retain customers. Its forecast-based Business Intelligence model has been calibrated to cater a wide spectrum of stakeholders across the board. VALOORES'' forecasting model is equipped with the capacity of funneling decomposed information through outlier detection, defining confidence intervals and guardrails, identifying seasonality and variations, with a self-adjusting accuracy mechanism. All this is governed by a progressive GUI engine, extrapolating models results to visual actionable information.",
        link: ''
    },
    {
        img: News24,
        title: 'VALOORES delivers a world class Master Data Management to the Banking & Finance industry.',
        date:'October 15, 2014',
        desc: 'Following its proliferating Master Data Management implementations, VALOORES introduced the "Single Version of the Truth" to the Banking & Finance industry. VALOORES focused on alleviating the financial challenges in designing decision making vehicles, with an environment of heterogeneous scattered systems. VALOORES Master Data Management secures information quality and integrity, through governing operational, transactional and processed data, and modeling a consistent and reliable foundation. Master Data Management replaces multiple ownerships, with diverging cultures and disciplines, by a solid foundation that sustains future road maps.',
        link: ''
    },
    {
        img: News25,
        title: 'VALOORES expands its footprint in financial solutions and goes live with Money Transfer solutions.',
        date:'September 30, 2014',
        desc: 'VALOORES, Leading Solution Provider, goes live with BOB Finance in more than 800 locations. Money Transfer industry is branching out with proliferating agents, governing progressive business processes, with challenges in sustaining consistency across silos, availability to an exponential growth of agents coming on board, and robustness in maintaining a highly transactional work flow. VALOORES tackled this industrys pain through designing a dynamic model that sustains the diversity of Money Transfer disciplines, a robust Master Data Management foundation that secures integrity across all operations, and compliance with corporate strategies on Cash Management, Fidelity, Card Management, Billing, & Payment.',
        link: ''
    },
]

export default NewsArray;