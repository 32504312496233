import React from 'react'
import { FaHome, FaCalendarAlt } from "react-icons/fa";
import { MdContactEmergency } from "react-icons/md";
import { Link } from 'react-router-dom';
import mainLogo from '../../../../../img/logoDouaihi.png'
import './Sidebar.css'

const Sidebar = ({handleSidebarPages, selectedPage}) => {
  return (
      <div className="sidebar py-3 shadow">
            <div className="sidebar_logo">
                <img src={mainLogo} className="w-75" alt="" />
            </div>
            <div className="menu_list">
                <ul className="p-0 m-0">
                    <Link id="IndividualId" className={`menu_list-items ${selectedPage === 'Individual' ? 'active' : ''}`} onClick= {() => handleSidebarPages('Individual')}>
                        <FaHome />
                        <li>Individual Submissions</li>
                    </Link>
                    <Link id="GroupId" className={`menu_list-items ${selectedPage === 'Group' ? 'active' : ''}`} onClick= {() => handleSidebarPages('Group')}>
                        <MdContactEmergency />
                        <li>Group Submissions</li>
                    </Link>
                    
                </ul>
            </div>
        </div>
  )
}

export default Sidebar
