import React from 'react';
import {Link} from 'react-router-dom'
import RetailEn from '../../videos/Retail-EN.mp4'

  
  const Introduction = () => {
   
  return (
    <>
      <div className="container mt-5 intro_container">
        <div className="row align-items-center">
            <div className="col-md-6">
              <div className="video_player-container">
                <video width="100%" height="320" controls controlsList="nodownload">
                  <source src={RetailEn} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content__title">
                <h2>
                  <span>VALOORES in 3 Lines</span>
                </h2>
                <p>
                  VALOORES empowers decision making, helping people and businesses around the globe reach their targets. Founded in 1989 in France, the Company is a pioneer in Master Data Governance, Retail & Merchandising, Supply Chain Optimization, KYC, Regulatory Compliance, Financial Crime - AML & Fraud, Predictive Analytics and Data Science to improve their on-going operations, executions and decisions. &nbsp;
                    <Link to='https://valoores.com/about.php' target= '_blank'>Read More</Link>
                </p>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Introduction