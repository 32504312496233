import React from 'react'
import NewsArray from './NewsArray'
import { Link } from 'react-router-dom'

const NewsSection = () => {
  return (
    <>
    <div className='d-flex flex-column gap-4 my-3'>
        {NewsArray.map((news, index) => (
            <div key={index} className='row'>
                  {/* <img src={news.img} className='w-100' alt="" /> */}
                  <Link target='_blank' to={news.link} className='d-flex  flex-row text-dark gap-3' style={{textDecoration: 'none'}}>
                    <div className="col-md-3 news_img" style={{backgroundImage: `url(${news.img})`}}></div>
                    <div className="col-md-9 d-flex flex-column">
                      <h6 className='text-primary'>{news.title}</h6>
                      <small className='text-secondary ' >{news.date}</small>
                      <p>{news.desc}</p>
                    </div>
                  </Link>
            </div>
        ))}
    </div>
    </>
  )
}

export default NewsSection
