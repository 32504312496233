import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MemberTable from './MemberTable';
import QRCode from 'qrcode.react';

const GroupForm = ({ language, options }) => {
  const [organizationDetails, setOrganizationDetails] = useState({
    name: '',
    members: [],
  });
  const [memberForm, setMemberForm] = useState({
    fullName: '',
    email: '',
    phone: '',
  });
  const [showMemberTable, setShowMemberTable] = useState(false);
  const qrCodeRef = useRef(null);
  const [qrCodeDataURL, setQRCodeDataURL] = useState('');
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const selectedEventsG = Object.entries(options)
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

  useEffect(() => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const dataURL = canvas.toDataURL();
        setQRCodeDataURL(dataURL);
      }
    }
  }, [organizationDetails.name, memberForm.fullName, memberForm.email, memberForm.phone]);

  const sendEmailWithQR = (qrCodeDataURL, id) => {
    const emailGData = {
      to: memberForm.email,
      subject: 'Your QR Code for Event Registration',
      content: `
        <p>Hello ${memberForm.fullName},</p>
        <p>Here is your QR Code for the group ${organizationDetails.name} to attend the following event: ${selectedEventsG.join(', ')}</p>
        <p>Click <a href="http://localhost:3000/PatriarchDouaihi/add/${id}">here</a> to view your group details.</p>
        <p>Regards,<br/>Event Team</p>
      `,
      qrCodeDataURL: qrCodeDataURL,
    };

    axios.post('http://127.0.0.1:8000/api/send_email', emailGData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  const handleOrganizationSubmit = (e) => {
    e.preventDefault();
    if (organizationDetails.name && memberForm.fullName && memberForm.email && memberForm.phone) {
      setShowMemberTable(true);
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const handleMemberFormChange = (e) => {
    const { name, value } = e.target;
    setMemberForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleAddMember = () => {
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      members: [...prevDetails.members, { fullName: '', phone: '', email: '' }],
    }));
  };

  const handleMemberInputChange = (index, field, value) => {
    const updatedMembers = organizationDetails.members.map((member, i) =>
      i === index ? { ...member, [field]: value } : member
    );
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      members: updatedMembers,
    }));
  };

  const handleShowMemberTable = () => {
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      members: prevDetails.members.length > 0 ? prevDetails.members : [{ ...memberForm }],
    }));
    setShowMemberTable(true);
    setSubmitDisabled(organizationDetails.members.length < 1); 
  };

  const handleGroupFormSubmit = (e) => {
    // e.preventDefault();
    // if (organizationDetails.name && memberForm.fullName && memberForm.email && memberForm.phone) {
    //   axios.post('http://127.0.0.1:8000/api/uploadAttend', {
    //     ev: selectedEventsG,
    //     lang: language,
    //     type: 'Group',
    //     grp: {
    //       fullName: memberForm.fullName,
    //       email: memberForm.email,
    //       phone: memberForm.phone,
    //       organization: organizationDetails.name,
    //     },
    //     members: organizationDetails.members.map(member => ({
    //       fullName: member.fullName,
    //       email: member.email,
    //       phone: member.phone,
    //     })),
    //   })
    //     .then(response => {
    //       const id = response.data.id;
    //       if (id) {
    //         sendEmailWithQR(qrCodeDataURL, id);
            navigate('/PatriarchDouaihi/qr-code-group', {
              state: {
                fullName: memberForm.fullName,
                email: memberForm.email,
                phone: memberForm.phone,
                events: selectedEventsG.join(', '),
                name: organizationDetails.name,
              },
            });
    //       } else {
    //         console.error('Error: ID not returned from the backend');
    //         alert('An error occurred while submitting the form. Please try again.');
    //       }
    //     })
    //     .catch(error => {
    //       console.error('Error:', error);
    //       alert('An error occurred while submitting the form. Please try again.');
    //     });
    // } else {
    //   alert('Please fill in all fields.');
    // }
  };

  const handleBackClick = () => {
    const updatedMembers = organizationDetails.members.filter(member => member.fullName !== '' || member.phone !== '' || member.email !== '');
    setOrganizationDetails(prevDetails => ({
      ...prevDetails,
      members: updatedMembers,
    }));
    setShowMemberTable(false);
  };

  return (
    <div className={`form mt-5 px-1 bg-white shadow-lg rounded w-100 ${language === 'Arabic' ? 'rtl-switch' : ''}`}>
      {!showMemberTable ? (
        <form className='p-3' onSubmit={handleGroupFormSubmit}>
          <h3 className='mb-4 text-center patriarch-color'>{language === 'Arabic' ? 'مجموعة' : 'Group'}</h3>
          <div className="form-group">
            <input
              type="text"
              className="form-control patriarch-color"
              placeholder={language === 'Arabic' ? 'اسم المنظمة' : 'Organization Name'}
              value={organizationDetails.name}
              onChange={(e) => setOrganizationDetails({ ...organizationDetails, name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control patriarch-color"
              name="fullName"
              placeholder={language === 'Arabic' ? 'الاسم الكامل' : 'Full Name'}
              value={memberForm.fullName}
              onChange={(e) => handleMemberFormChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control patriarch-color"
              name="email"
              placeholder={language === 'Arabic' ? 'البريد الإلكتروني' : 'Email'}
              value={memberForm.email}
              onChange={(e) => handleMemberFormChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              className="form-control patriarch-color"
              name="phone"
              placeholder={language === 'Arabic' ? 'رقم الهاتف' : 'Phone'}
              value={memberForm.phone}
              onChange={(e) => handleMemberFormChange(e)}
            />
          </div>
          <span className='fw-bold' style={{ color: '#e0a93a' }}>* At least 25 members are needed to continue</span>
          <div className='d-flex flex-column gap-3'>
            {organizationDetails.name && memberForm.fullName && memberForm.email && memberForm.phone && (
              <button type="button" className="btn btn-patriarch ml-3 w-100" onClick={handleShowMemberTable}>
                {organizationDetails.members.length > 0
                  ? (language === 'Arabic' ? 'عرض الأعضاء' : 'Show Members')
                  : (language === 'Arabic' ? 'إضافة الأعضاء' : 'Add Members')}
              </button>
            )}
            <button type="submit" className="btn btn-patriarch w-100 mt-0" disabled={submitDisabled}>
              {language === 'Arabic' ? 'إرسال' : 'Submit'}
            </button>
          </div>
        </form>
      ) : (
        <MemberTable
          language={language}
          members={organizationDetails.members}
          onInputChange={handleMemberInputChange}
          onAddMember={handleAddMember}
          onBack={handleBackClick}
        />
      )}
      <div ref={qrCodeRef} style={{ display: 'none' }}>
        <QRCode value={`Orgarnization: '${organizationDetails.name}' - Name: '${memberForm.fullName}' - Email:'${memberForm.email}' - Phone: '${memberForm.phone}'`} />
      </div>
    </div>
  );
};

export default GroupForm;
