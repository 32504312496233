import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import Patriarch from '../../img/patriarch1.png';
import { Modal, Button } from 'react-bootstrap';

const QRCodeDisplayIndividual = () => {
  const location = useLocation();
  const formData = location.state;
  const [readDocs, isReadDocs] = useState(false);
  const [showModal, setShowModal] = useState(true);

  if (!formData) {
    return <div>No data found.</div>;
  }

  // Format data for display
  const formattedData = `
    First Name: ${formData.firstName}
    Last Name: ${formData.lastName}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Events: ${formData.events}
  `;

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  
  const handleAccept = () => {
    isReadDocs(true);
    setShowModal(false);
  }

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>  
          <Modal.Title>General Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "50dvw", overflowY: "scroll"}}>
          <ul>
            <li>Please have your QR code ready in order to access the event.</li>
            <li>All dependents must arrive together to enter the venue. Otherwise, register as individuals.</li>
            <li>No cars will have access to the roads leading to Bkerki!</li>
            <li>After parking in the designated area, you will be picked up and driven to and from the above-stated parking area to Bkerki in a bus supplied by the event.</li>
            <li>Doors will open at 6:00 pm (a special program will be prepared from 6-8 pm prior to the beatification event).</li>
            <li>For your convenience and safety, a brief search will be conducted.</li>
            <li>Please leave all food in your vehicles! Food is prohibited on site.</li>
            <li>Be in appropriate dress code as we respect this holy day.</li>
            <li>Once inside, seating is available on a first-come, first-served basis. Feel free to choose your preferred spot. Moving the seats is not allowed. Sitting in the reserved seats is not allowed.</li>
            <li>In consideration of others attending the event, we kindly request that all participants silence their phones and remain seated throughout the duration of the mass.</li>
            <li>If assistance is needed, do not hesitate to approach event staff or volunteers stationed throughout the venue.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

      {readDocs && 
        <div d-flex flex-column align-items-center justify-content-center gap-3 p-5 bg-white rounded
          className="banner-patriarch d-flex align-items-start justify-content-center align-items-center"
          style={{ backgroundImage: `url(${Patriarch})` }}
        >
          <div className="overlay"></div>
          <div className="qr-code-display d-flex flex-column align-items-center justify-content-center gap-3 p-5 rounded" style={{background: '#fff'}}>
            <h3 className='patriarch-color'>Scan this QR code to get more information</h3>
            <QRCode value={formattedData} renderAs="svg" />
            <div className="form-data">
              <h4>Events Detail:</h4>
              <p><strong>Name:</strong> {formData.firstName} {formData.lastName}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Phone:</strong> {formData.phone}</p>
              <p><strong>Event:</strong> {formData.events}</p>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default QRCodeDisplayIndividual;
