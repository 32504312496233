import React from 'react';

const MemberTable = ({ language, members, onInputChange, onAddMember, onBack }) => {
  const memberCount = members.length;

  const isAnyRowEmpty = () => {
    return members.some(member => member.fullName === '' || member.phone === '' || member.email === '');
  };

  const handleBackClick = () => {
    if (members.length > 0) {
      const lastMember = members[members.length - 1];
      if (lastMember.fullName === '' && lastMember.phone === '' && lastMember.email === '') {
        onBack(members.slice(0, members.length - 1));
      } else {
        onBack(members);
      }
    }
  };

  return (
    <div className="member-table px-3">
      <div className="d-flex justify-content-between align-items-center my-3">
        <button onClick={handleBackClick} className="btn btn-patriarch">
          {language === 'Arabic' ? 'رجوع' : 'Back'}
        </button>
        <span style={{fontSize: '1rem'}}>{`${memberCount} ${language === 'Arabic' ? 'أعضاء' : 'Members'}`}</span>
      </div>
      <h3 className='patriarch-color'>{language === 'Arabic' ? 'إدخال بيانات الأعضاء' : 'Enter Member Details'}</h3>
      <div style={{ height: '10rem', overflowY: 'scroll' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ fontSize: '1rem' }}>{language === 'Arabic' ? 'الاسم الكامل' : 'Full Name'}</th>
              <th style={{ fontSize: '1rem' }}>{language === 'Arabic' ? 'رقم الهاتف' : 'Phone Number'}</th>
              <th style={{ fontSize: '1rem' }}>{language === 'Arabic' ? 'البريد الإلكتروني' : 'Email'}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    className="form-control patriarch-color"
                    placeholder={language === 'Arabic' ? 'الاسم الكامل' : 'Full Name'}
                    value={member.fullName}
                    onChange={(e) => onInputChange(index, 'fullName', e.target.value)}
                    disabled={index === 0}
                  />
                </td>
                <td>
                  <input
                    type="tel"
                    className="form-control patriarch-color"
                    placeholder={language === 'Arabic' ? 'رقم الهاتف' : 'Phone Number'}
                    value={member.phone}
                    onChange={(e) => onInputChange(index, 'phone', e.target.value)}
                    disabled={index === 0}
                  />
                </td>
                <td>
                  <input
                    type="email"
                    className="form-control patriarch-color"
                    placeholder={language === 'Arabic' ? 'البريد الإلكتروني' : 'Email'}
                    value={member.email}
                    onChange={(e) => onInputChange(index, 'email', e.target.value)}
                    disabled={index === 0}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        className="btn btn-patriarch w-100 mt-3"
        onClick={onAddMember}
        disabled={isAnyRowEmpty()} 
      >
        {language === 'Arabic' ? 'إضافة فرد آخر' : 'Add Another Member'}
      </button>
    </div>
  );
};

export default MemberTable;
