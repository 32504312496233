import React from 'react'
import {Link} from 'react-router-dom'
import laptop from '../../img/laptop.png'
import valooresdashboard from '../../img/insurance/paralax-2.jpg'

const Paralax = (props) => {
  return (
    <>
       
            <div className=" d-flex flex-column align-items-center justify-content-center" style={{top: `${props.top}`, backgroundImage:`url(${valooresdashboard})`, height: '250px', backgroundSize: 'cover', backgroundPosition:'center center', backgroundAttachment:'fixed'}}>
                <h2 className='text-center text-white px-4 w-75'>"To Add Value to others, one must first Value others" </h2>
                
           
        </div>
    </>
  )
}

export default Paralax