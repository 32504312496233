import React from 'react';
import { Link } from 'react-router-dom';

const JobsCat = (
    {
        categories,
        handleActive,
        activeJob,
        scrollToTargetSection
    }) => {
        

    
  

  return (
    <>
        <div className='jobs_container d-flex flex-column align-items-center gap-3'>
            <h1 className='text-center' style={{fontSize: '3rem'}}>
                We're looking for people with<br /> passion and purpose. What's yours?
            </h1>
            <div className='d-flex flex-row align-items-center justify-content-center gap-3'>
                <Link to= 'https://form.jotform.me/92441014348451' target='_blank'>
                    <button className='btn jobs_btn'>Apply Now</button>
                </Link>
                <Link to= 'https://form.jotform.me/241432892183458' target='_blank'>
                    <button className='btn jobs_btn'>Looking for an Internship</button>
                </Link>
            </div>
        </div>
        <div className="container d-flex align-items-center">
            <div className='job_categories d-grid gap-3 my-5 w-100'>
                {categories.map((cat, index) => (
                    <div onClick={() => {
                        handleActive(index, `${cat.job}`);
                        scrollToTargetSection();
                      }} className={activeJob === index ? 'job_category active_overlay' : 'job_category'} style={{position: 'relative', cursor: 'pointer'}}>
                    <div style={{position: 'absolute', backgroundImage: `url(${cat.img})`}} className='w-100 overlay-border jobBg'  key={index}></div>
                    <div className="overlay-bg"></div>
                        <div className='d-flex flex-column gap-4 w-100 text-white' style={{position: 'absolute', zIndex: '4', textShadow: '3px 3px 6px black', top: '15%'}}>
                            <h4>{cat.job}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default JobsCat