import React from 'react';

const FamilyMemberTable = ({ language, members, onInputChange, onAddMember }) => {
  const isLastMemberEmpty = () => {
    const lastMember = members[members.length - 1];
    return !lastMember.firstName || !lastMember.lastName;
  };

  return (
    <div className="member-table">
      <h3 className='text-white'>{language === 'Arabic' ? 'إدخال بيانات أفراد العائلة' : 'Enter Family Member Details'}</h3>
      <div style={{ height: '15rem', overflowY: 'scroll' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ fontSize: '1rem' }}>{language === 'Arabic' ? 'الاسم الأول' : 'First Name'}</th>
              <th style={{ fontSize: '1rem' }}>{language === 'Arabic' ? 'اسم العائلة' : 'Last Name'}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    className="form-control patriarch-color"
                    placeholder={language === 'Arabic' ? 'الاسم الأول' : 'First Name'}
                    value={member.firstName}
                    onChange={(e) => onInputChange(index, 'firstName', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control patriarch-color"
                    placeholder={language === 'Arabic' ? 'اسم العائلة' : 'Last Name'}
                    value={member.lastName}
                    onChange={(e) => onInputChange(index, 'lastName', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        className="btn btn-patriarch w-100 mt-3"
        onClick={() => {
          if (!isLastMemberEmpty()) {
            onAddMember();
          }
        }}
      >
        {language === 'Arabic' ? 'إضافة فرد آخر' : 'Add Another Member'}
      </button>
    </div>
  );
};

export default FamilyMemberTable;
