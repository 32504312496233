import React, { useState } from 'react';
import Sidebar from '../Components/Sidebar/Sidebar';
import MainContent from '../Components/MainContent/MainContent';
import LoginForm from './LoginForm';
import bannerImg from '../../../../img/patriarch1.png'
import './HomeDashboard.css';
import axios from 'axios';

const HomeDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/getAdmin', { username, password });
      const responseData = response.data;
  
      // Check if the response indicates successful login
      if (response.status === 200 && response.statusText === 'OK') {
        setIsLoggedIn(true);
        setError('');
      } else {
        setIsLoggedIn(false);
        setError('Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Failed to login. Please try again.');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
    setError('');
  };

  const handleSidebarPages = (page) => {
    setSelectedPage(page);
  };

  const [selectedPage, setSelectedPage] = useState('Home');

  if (!isLoggedIn) {
    return (
    <div className='d-flex align-items-center justify-content-center w-100' style={{height: '100vh', backgroundImage: `url(${bannerImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      <LoginForm
        username={username}
        password={password}
        setUsername={setUsername}
        setPassword={setPassword}
        error={error}
        handleLogin={handleLogin}
      />
    </div>
    )
  }

  return (
    <div className='d-flex flex-row'>
      <div style={{ flex: '1' }}>
        <Sidebar handleSidebarPages={handleSidebarPages} selectedPage={selectedPage} handleLogout={handleLogout} />
      </div>
      <div style={{ flex: '4' }}>
        <MainContent selectedPage={selectedPage} />
      </div>
    </div>
  );
};

export default HomeDashboard;
